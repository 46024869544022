/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';

export const getCurrentBrandId = (state) => ({ ...{ ...state }.ui }.brandId) || '';

export const getBrandsById = (state) => {
    if (state && state.page && state.page.brands && state.page.brands.byId) {
        return state.page.brands.byId;
    }

    return null;
};

// select(getBrand) returns object containing all details about current brand
export const getBrand = createSelector(
    [
        getCurrentBrandId,
        getBrandsById,
    ],
    (currentBrandId, brandsById) => {
        if (currentBrandId && brandsById && brandsById[currentBrandId]) {
            return brandsById[currentBrandId];
        }

        return null;
    },
);

export const getCustomerEmail = (state) => {
    if (state && state.member && state.member.profile && state.member.profile.email) {
        return state.member.profile.email;
    }
    return '';
};
