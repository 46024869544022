/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import {
    object, string, func,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import { ducks as checkoutDucks } from '../../../../state/ducks/Checkout';

const {
    common: { commonSelectors },
} = checkoutDucks;

const styles = (theme) => ({
    favIcon: {
        width: '24px',
        height: '24px',
        color: theme.palette.common?.black,
        verticalAlign: 'middle',
    },
    favMobile: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '4',
        fontSize: '10px',
        textDecoraction: 'none',
        textTransform: 'uppercase',
        letterSpacing: '.2px',
        borderRadius: '50%',
        padding: '6px 0px 12px',
        marginLeft: '50px',
        marginTop: '8px',
        color: theme.palette.common?.black,
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'none',
        height: '42px',
        fontFamily: theme.typography?.fontFamily,
        position: 'relative',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
});

const Favorites = ({
    classes, trackEvent, type,
}) => {
    const favIcon = (
        <svg className={classes.favIcon} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
        </svg>
    );
    if (type === 'mobile') {
        return (
            <Link to="/favorites" className={classes.favMobile} aria-label="favorites" aria-labelledby="favorites">
                {favIcon}
            </Link>
        );
    }

    return (
        <Link
            to="/favorites"
            id="hp-cart-fav"
            data-test="hp-cart-fav-icon"
            name="favIcon"
            onClick={() => trackEvent({
                eventName: 'feature_interaction', eventCategory: 'Header', eventAction: 'Favorite', eventLabel: '<<pageType>>',
            })}
            aria-label="favorites"
            aria-labelledby="favorites"
        >
            {favIcon}
            {/* In production, item count comes from a cookie */}
        </Link>
    );
};

Favorites.propTypes = {
    classes: object.isRequired,
    trackEvent: func.isRequired,
    type: string,
};

Favorites.defaultProps = {
    type: 'desktop',
};

const mapDispatcherToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

const mapStateToProps = (state) => ({
    orderStatus: commonSelectors.getOrderStatus(state),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatcherToProps),
)(Favorites);
