/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
    Render the horizontal desktop header nav menu based on object array produced by MenuBuilder
    sourced from CMS/GraphQL.

    Each node of object array structure has:
        label           menu text for top menu item
        link            relative or absolute URL for where user navigates when item clicked (if no submenu)
        submenu         if this menu item has a submenu,
                        this is an array of its submenu items

    Each submenu node has:
        label           menu text
        link            relative or absolute URL for where user navigates when item clicked
        linkStyle       what kind of thing it is.
                        LINKSTYLE_NORMAL - normal menu item and link
                        LINKSTYLE_ALTERNATE - menu link with alternate styling
                        LINKSTYLE_HEADING - heading style; link optional
                        LINKSTYLE_BUTTON - display the menu link like a button
                        LINKSTYLE_TITLE - it's a static title, not a menu link
                        LINKSTYLE_DIVIDER - it's a divider line, not a menu link
                        LINKSTYLE_WIDGET - it's a widget, not a menu link

*/
import React from 'react';
import {
    array, object, shape, string,
} from 'prop-types';
import { UIDReset, UIDConsumer } from 'react-uid';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loadable from '@loadable/component';
import { getLastVisted } from '../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';

const DesktopGqlTopNavMenuItem = loadable(() => import(/* webpackChunkName: "DesktopTopNavMenuItem" */ './Items/DesktopTopNavMenuItem'));

const styles = () => ({
    desktopNavMenu: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        margin: 0,
        padding: '0',
        alignItems: 'flex-end',
        height: '100%',
        width: '100%',
    },
    navMenuContainer: {
        maxWidth: '1400px',
        width: '100%',
        display: 'flex',
    },
    plantsNavMenuContainer: {
        display: 'flex',
        zIndex: '1081',
        height: '105px',
        position: 'fixed',
        '@media (max-width: 1264px)': {
            // paddingLeft: '24px !important',
            marginRight: '50px !important',
        },
        alignItems: 'center',
        padding: '30px 0',
        top: '45px',
    },
    plantsOrderConfirmNavMenuContainer: {
        display: 'flex',
        zIndex: '1081',
        height: '105px',
        position: 'fixed',
        '@media (max-width: 1264px)': {
            paddingLeft: '24px !important',
        },
        alignItems: 'center',
        top: '45px',
        padding: '30px 0',
    },
    alternateNavMenu: {
        display: 'flex',
        margin: '0 100px',
        height: '100%',
    },
});
const LINKSTYLE_ALTERNATE = 'Alternate';
const DesktopNavMenu = (props) => {
    const {
        navMenuList, attributes, classes, brand, presentation_family, getPageUrl,
    } = props;
    const navMenuCon = getPageUrl.includes('/order-confirmation/') ? classes.plantsOrderConfirmNavMenuContainer : classes.plantsNavMenuContainer;
    const alternateMenuList = [];
    let primaryMenuList = navMenuList;
    if (presentation_family === 'food') {
        primaryMenuList = navMenuList.filter((menu) => {
            let response = true;
            if (menu.linkStyle === LINKSTYLE_ALTERNATE) {
                alternateMenuList.push(menu);
                response = false;
            }
            return response;
        });
    }
    // Override styles of menus and container from contentstack
    const containerStyleOverride = {};
    const menuStlyeOverride = {};
    let justifyContent = 'center';
    Object.keys(attributes).forEach((attribute) => {
        if (attributes[attribute]) {
            switch (attribute) {
                case 'menu_width':
                    menuStlyeOverride.width = attributes[attribute];
                    break;
                case 'align_menu_items':
                    if (attributes[attribute] === 'Left') justifyContent = 'flex-start';
                    if (attributes[attribute] === 'Right') justifyContent = 'flex-end';
                    containerStyleOverride.justifyContent = justifyContent;
                    break;
                case 'padding':
                    containerStyleOverride.padding = `0 ${attributes[attribute]}px`;
                    break;
                case 'background_color':
                    if (brand.code === 'PLA') containerStyleOverride.backgroundColor = 'transparent';
                    break;
                default:
                    break;
            }
        }
    });
    const menuItemIterator = (menuList) => (
        <UIDReset>
            <UIDConsumer>
                {(id, uid) => menuList.map(
                    (topMenuItem, menuIndex) => (
                        <DesktopGqlTopNavMenuItem
                            key={uid(topMenuItem)}
                            item={topMenuItem}
                            attributes={attributes}
                            brand={brand}
                            subMenuLength={topMenuItem?.submenu?.length}
                            index={menuIndex + 1}
                            length={navMenuList.length}
                            label={topMenuItem.label}
                        />
                    ),
                )}
            </UIDConsumer>
        </UIDReset>
    );

    return (
        <div id="navMenu" style={containerStyleOverride} className={brand.code === 'PLA' ? navMenuCon : classes.navMenuContainer}>
            <ul style={menuStlyeOverride} className={classes.desktopNavMenu}>
                {menuItemIterator(primaryMenuList)}
                {alternateMenuList?.length > 0
            && (
                <div className={classes.alternateNavMenu}>
                    {menuItemIterator(alternateMenuList)}
                </div>
            )}
            </ul>
        </div>
    );
};

DesktopNavMenu.propTypes = {
    navMenuList: array.isRequired,
    attributes: object,
    classes: object.isRequired,
    presentation_family: string.isRequired,
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    getPageUrl: string.isRequired,
};

DesktopNavMenu.defaultProps = {
    attributes: {},
};
const mapStateToProps = (state) => ({
    getPageUrl: getLastVisted(state),
});
export default connect(mapStateToProps)(withStyles(styles)(DesktopNavMenu));
