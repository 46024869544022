/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useState } from 'react';
import {
    object, string, shape, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Container } from '@material-ui/core';
// ui components
import SignInDropdown from './partials/SignInDropdown';
import SignedInDropdown from './partials/SignedInDropdown';

// redux, gql, helpers
import memberDucks from '../../../../../state/ducks/Member/ducks';
import { trackData } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import { getBrand } from '../../../../pages/Account/state/ducks/Common/Common-Selectors';

const {
    common: {
        commonSelectors,
    },
} = memberDucks;

const styles = (theme) => ({
    signInContainer: {
        cursor: 'pointer !important',
        position: 'relative !important',
        textAlign: 'center !important',
        fontSize: '12px !important',
        color: '#000 !important',
        backgroundColor: 'transparent !important',
        border: 0,
        paddingBottom: 0,
        paddingTop: 0,
        /* minWidth: 40, */
        '&:hover $signInDropdown, &:focus $signInDropdown, &:active $signInDropdown': {
            visibility: 'visible !important',
            opacity: 1,
            transform: 'translate(-50%,0) !important',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12) !important',
        },
    },
    plaSignInContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    signInDropdown: {
        visibility: 'hidden !important',
        opacity: 0,
        transition: 'opacity 332ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 221ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        boxShadow: 'none !important',
        zIndex: '999 !important',
        width: '260px !important',
        padding: '10px !important',
        position: 'absolute !important',
        top: '100% !important',
        left: '50% !important',
        transform: 'translate(-50%,7px) !important',
        backgroundColor: '#FFF !important',
        border: '1px solid #ccc !important',
    },
    signInButton: {
        display: 'block !important',
        width: '100% !important',
        lineHeight: '30px !important',
        position: 'relative !important',
        backgroundColor: `${theme.palette.colorPrimary} !important`,
        color: '#000',
        textDecoration: 'none !important',
        fontWeight: 'bold !important',
        fontSize: '14px !important',
        textAlign: 'center !important',
        marginBottom: '10px !important',
        '&:hover': {
            backgroundColor: `${theme.palette.cms?.primary || theme.palette.colorPrimary} !important`,
        },
        '&:focus': {
            outline: 'none',
        },
    },
    createAccountContainer: {
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '10px',
        '& p': {
            margin: 0,
            display: 'block',
        },
    },
    clickLink: {
        color: theme.palette.common?.black || '#000',
        textDecoration: 'none',
        paddingTop: 0,
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent !important',
        },
    },
    clickLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        textTransform: 'none',
    },
    facebookButton: {
        color: '#FFF !important',
        marginBottom: '8px !important',
        borderRadius: '3px !important',
        display: 'flex !important',
        alignItems: 'center !important',
        width: '100% !important',
        backgroundColor: '#3b5998 !important',
        lineHeight: '28px !important',
        textTransform: 'none !important',
        '&:focus': {
            outline: '1px solid',
        },
        '&:hover': {
            backgroundColor: '#3b5998 !important',
        },
        '& span': {
            fontSize: '13px !important',
            marginLeft: '10px !important',
            color: `${theme.palette.colorPrimaryContrast} !important`,
        },
    },
    googleButton: {
        marginBottom: '10px !important',
        borderRadius: '3px !important',
        display: 'flex !important',
        alignItems: 'center !important',
        width: '100% !important',
        backgroundColor: '#FFF !important',
        lineHeight: '28px !important',
        textTransform: 'none !important',
        border: `1px solid ${theme.palette.colorNeutral40} !important`,
        '&:focus': {
            outline: `1px solid ${theme.palette.colorNeutral30}`,
        },
        '& span': {
            textDecoration: 'none !important',
            color: `${theme.palette.colorNeutral80} !important`,
            fontSize: '13px !important',
            marginLeft: '10px !important',
        },
        '&:hover': {
            backgroundColor: `${theme.palette.colorPrimaryContrast} !important`,
        },
    },
    actionsWrapper: {
        marginBottom: '10px',
        lineHeight: '1.7',
        '& a$action': {
            textAlign: 'left',
            color: '#000',
        },
    },
    action: {
        display: 'block',
        textDecoration: 'none',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    benefits: {
        border: `2px solid ${theme.palette.colorNeutral60}`,
        borderRadius: '5px',
        padding: '5px 10px 5px 10px',
        lineHeight: '1.5',
        fontSize: '14px',
        textAlign: 'left',
        '& ul': {
            margin: '0',
            padding: '0 0 0 16px',
            fontSize: '13px',
            '& li': {
                margin: 0,
                textAlign: 'left',
            },
        },
    },
    actionsWrapperIcon: {
        '& a$action': {
            textAlign: 'left',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            display: 'flex',
            alignItems: 'center',
            padding: '1.1px 0',
            '& svg': {
                marginRight: '6px',
                width: '24px',
            },
        },
    },
    wrapperBox: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: '15px',
    },
    noPadding: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    smallText: {
        fontSize: '0.5625rem',

    },
});

const SignIn = ({
    classes, image, getFullName, isAuthenticated, reminderBlock, brand,
}) => {
    const [tagImpressionState] = useState(null);
    const shortenFit = (s) => (s.length <= 6 ? s : `${s.substr(0, 6)}…`);
    trackData({
        tagImpressionState, // data provided
        action: 'track_siginin_dropdown',
    });
    const isPlants = brand.code === 'PLA';
    return (
        <>
            <Container className={`${classes?.wrapperBox} ${isPlants && classes.noPadding}`}>
                <div id="signInDropdown" data-test="sign-in-dropdown" name="signinDropdown" className={`${classes.signInContainer} ${isPlants && classes.plaSignInContainer}`}>
                    <img src={image || ''} alt="Sign In Icon" aria-hidden="true" role="presentation" data-test="hp-sign-in-icon" id="hp-sign-in" name="signInIcon" height="20" width="20" />
                    <div className={isPlants && classes.smallText}> {isAuthenticated && getFullName && getFullName?.firstName !== '' ? shortenFit(getFullName.firstName) : 'Log In'}</div>
                    <div
                        className={classes.signInDropdown}
                        id="sign-in-dropdown"
                    >
                        {!isAuthenticated ? (
                            // if not signed in
                            <SignInDropdown classes={classes} reminderBlock={reminderBlock} />
                        ) : (
                            // if signed in
                            <SignedInDropdown classes={{ actionsWrapper: classes.actionsWrapper, action: classes.action, actionsWrapperIcon: classes.actionsWrapperIcon }} reminderBlock={reminderBlock} />
                        )}
                    </div>
                </div>
            </Container>
        </>
    );
};

const mapStateToProps = (state) => ({
    getFullName: commonSelectors.getFirstLastName(state),
    isAuthenticated: commonSelectors.getIsAuthenticatedStatus(state),
    brand: getBrand(state),
});

SignIn.propTypes = {
    classes: object.isRequired,
    image: string.isRequired,
    getFullName: shape({
        firstName: string.isRequired,
        lastName: string.isRequired,
    }).isRequired,
    isAuthenticated: bool.isRequired,
    reminderBlock: object.isRequired,
    brand: object.isRequired,
};

const enhance = compose(
    connect(mapStateToProps, null),
    withStyles(styles),
);

export default enhance(SignIn);
