/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import processCss from './generateValidCss';

// Convert simple markdown into elements and add inline styles
// ReactMarkdown will not parse styles

export default function parseMarkdown(markdown, styles) {
    const element = {};
    const parsedMarkdown = {};
    const markdownArr = markdown.split(' ');
    const elID = markdownArr[0];
    let hasStyles = '';
    const identifyElement = () => {
        switch (true) {
            case (elID.includes('#')):
                parsedMarkdown.ele = `h${elID.length}`;
                markdownArr.shift();
                break;
            default:
                parsedMarkdown.ele = 'div';
        }
        return `${parsedMarkdown.ele}`;
    };

    const parseValue = () => {
        const parsedValue = [];
        parsedMarkdown.value = markdownArr.map((i) => {
            switch (true) {
                case (i.includes('**')):
                    parsedValue.push(i.replace('**', `<${(/^\*\*.*/g).test(i) ?  '' : '/'}strong>`));
                    break;
                case (i.includes('*')):
                    parsedValue.push(i.replace('*', `<${(/^\*.*/g).test(i) ?  '' : '/'}em>`));
                    break;
                case (i.includes('^')):
                    parsedValue.push(i.replace('^', `<${(/^\^.*/g).test(i) ?  'sup style="line-height: 0;font-size: 8px;margin-left:-1em"' : '/'}sup>`));
                    break;
                case (i.includes('\n')):
                    parsedValue.push(i.replace('\n', '<br>'));
                    break;
                default:
                    parsedValue.push(`${i}`);
                    break;
            }
            return null;
        });
        return parsedValue.join(' ');
    };

    if (markdown !== undefined) {
        element.type = identifyElement();
    } else {
        mbpLogger.logWarning({ message: `parseMarkdown called but recieved ${markdown}` });
    }
    if (styles !== undefined && styles.length > 0) {
        hasStyles  = `style="${processCss(styles)}"`;
    }

    return `<${element.type} ${hasStyles}>${parseValue()}</${element.type}>`;
}
