/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { getFeatureFlags, getPassportPageUrl } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const SignedInDropdown = ({
    classes,
}) => {
    const dispatch = useDispatch();
    const handleEvents = (label) => dispatch(trackEvent({
        eventName: 'feature_interaction',
        eventCategory: 'Global Header',
        eventAction: 'Profile',
        eventLabel: `${label}|<<pageType>>`,
    }));
    return (
        <div className={classes.actionsWrapper}>
            <Link className={classes.action} to="/account" onClick={() => handleEvents('Your Account')} aria-label="your-account" aria-labelledby="your-account">Your Account</Link>
            <Link className={classes.action} to="/account/orders" onClick={() => handleEvents('Track Your Orders')} aria-label="track-order" aria-labelledby="track-order">Track Your Orders</Link>
            <hr />
            <Link to="/auth/logout" data-test="auth-logout" id="auth-logout-link" name="authLogoutLink" className={classes.action} aria-label="sign-out" aria-labelledby="sign-out">Sign Out</Link>
        </div>
    );
};

SignedInDropdown.propTypes = {
    classes: object.isRequired,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    passportPageUrl: getPassportPageUrl(state),
});

export default connect(mapStateToProps)(SignedInDropdown);
