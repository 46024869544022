/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 *  This component translates navigation menu content from WCS Composer or CMS/Graphql
 *  into a simplified menu object hierarchy used for input to either mobile hamburger menu or horizontal desktop menu.
 *
 *  target = 'Mobile' or 'Desktop'
 *       'Mobile' - after translating input, <NavMenu> is invoked to present mobile hamburger menu
 *       'Desktop' - after translating input, <DesktopNavMenu> is invoked to present horizontal desktop menu.
 *
 *  featureFlags['is-graphql-enabled'] = true or false
 *        true - input is a graphql object hierarchy originating from CMS
 *        false - input is a flat array originating from composer (see buildNavMenuFromComposer below)
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, {
    string, shape, arrayOf, bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';

import withStyles from '@material-ui/core/styles/withStyles';

import CloseIcon from '@material-ui/icons/Close';
import loadable from '@loadable/component';
import NavMenu from './NavMenu/NavMenu';
import DesktopNavMenu from '../../../../GraphqlComponents/GraphqlHeader/TopMenu/DesktopNavMenu/DesktopNavMenu';
import { getPresentationFamily, getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getLanguageName } from '../../../../../../state/ducks/Member/ducks/Auth/helper/helper';

const SlideNavMenu = loadable(() => import(/* webpackChunkName: "SlideNavMenu" */ './SlideNavMenu/SlideNavMenu'));

const TARGET_DESKTOP = 'Desktop';
const TARGET_MOBILE = 'Mobile';

// Supported by Composer and CMS/graphql
const LINKSTYLE_NORMAL = 'Normal';
const LINKSTYLE_ALTERNATE = 'Alternate';
const LINKSTYLE_DIVIDER = 'Divider';
// Supported by CMS/graphql only
const LINKSTYLE_HEADING = 'Heading';

const styles = () => ({
    divider: {
        height: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'vertical',
    },
    plantsSlideNavHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5%',
        borderBottom: '1px solid #919191',
        alignItems: 'center',
    },
    LogoMobilePlants: {
        maxHeight: '45px',
        maxWidth: '113px',
        margin: '0 20px',
    },
    plantsNavIcons: {
        fontSize: '1.2rem',
        color: '#7c7c7c',
    },
});

// translate composer or cms/graphql menu spec, then call <NavMenu> (mobile) or <DesktopNavMenu> component to present menu
class MenuBuilder extends Component {
    //
    // methods that build navmenu object hierarchy from composer input
    //

    /*
   *  buildNavMenuFromComposer --
   *
   *  input = array of objects: {label: 'composer-formatted label' , link: 'relative or absolute url'}
   *  output = menu object hierarchy suitable for input to NavMenu (mobile LeftMenu)
   *
   *  item's label contains the following magic characters to do formatting:
   *  label = '[[icon-name]] description'  -->  display given icon for menu item
   *  label = '~ description'  --> display menu item with alternate styling (e.g. inverse for H&D Account Links)
   *  (if icon and alternate formatting both desired, specify as '~[[icon-name]] description')
   *  label = '<<DIVIDER>>' --> display a gap between sections
   *  label = '> description'  --> item is a sub-menu item for prior top-level item (i.e. item that doesn't have >)
   *  label = '>> description'  --> item is a sub-menu item for prior item that begins >
   *  label = '>>> description'  --> item is a sub-menu item for prior item that begins >>
   *  supports any number of sub'ing with additional >'s
   *
   * Label examples:
   *   TopLevel Item "Summer Pleasures"
   *   Sub-Item "> Sun"
   *   Sub-Item "> Sand"
   *   Sub-Item "> Ice Cream"
   *   Sub-Sub-Item ">> Cones"
   *   Sub-Sub-Item ">> Sundaes"
   *
   * Example item:
   *  {label: "[[person]] My Account", link: "/AjaxLogonForm?MyAccount=Y&catalogId=11102&langId=-1&storeId=10202" }
   */
  buildNavMenuFromComposer = (menuArray, level, parentPosition, name, target) => {
      const newMenu = [];
      while (MenuBuilder.menuIndex < menuArray?.length) {
      // if there are menu items left to process...
          const currentItem = { ...menuArray[MenuBuilder.menuIndex] }; // copy .label and .link
          currentItem.url = '';
          // level = the number of leading >>>'s at beginning of label
          let newLevel = 0;
          const m = currentItem?.label?.match(/^(>+)\s*(\S.*\S)\s*$/);
          if (m) {
              newLevel = m[1]?.length;
              [, , currentItem.label] = m; // label with >>>'s removed
          }

          if (newLevel === level) {
              // at same level.  Just add this item.
              currentItem.level = level; // used for component key
              currentItem.parentPosition = parentPosition; // parent's index; used for panel expansion test
              // Use alternate styling if label begins with ~  (e.g. for H&D Account Links)
              if (currentItem?.label?.substring(0, 1) === '~') {
                  currentItem.linkStyle = LINKSTYLE_ALTERNATE;
                  currentItem.label = currentItem?.label?.substring(1)?.trim(); // remove leading ~
              } else if (currentItem?.label?.match('<<DIVIDER>>')) {
                  currentItem.linkStyle = LINKSTYLE_DIVIDER;
              } else {
                  currentItem.linkStyle = LINKSTYLE_NORMAL;
              }

              const m2 = currentItem?.label?.match(/^(\[\[\S+\]\])\s+(\S.*\S)\s*$/); // e.g.  '[[location-icon-white]] Find a Store'
              if (m2) {
                  [, currentItem.icon, currentItem.label] = m2;
              }

              newMenu.push(currentItem);
          } else if (newLevel > level) {
              // Going deeper.  Recursive call to add submenu.
              // Last item in newMenu is submenu's parent
              const parentIndex = newMenu?.length - 1;
              if (parentIndex >= 0) {
                  newMenu[parentIndex].submenu = this.buildNavMenuFromComposer(
                      menuArray,
                      level + 1,
                      parentIndex,
                      name,
                      target,
                  );
              }
          } else {
              // Going up.  We're done with current submenu.
              // Put this item back so caller will grab it.
              MenuBuilder.menuIndex -= 1;
              return newMenu;
          }

          MenuBuilder.menuIndex += 1;
      }
      return newMenu;
  };

  //
  // methods that build navmenu object hierarchy from cms/graphql input
  //

    // translate a CMS/Graphql item object into NavMenuItem object
    buildMenuItemFromGraphqlItem = (item, level, parentPosition = -1, label, name, targetPath, type) => {
        const { target } = this.props;
        const navMenuItem = {
            level,
            parentPosition,
            label: '',
            link: '',
            category: '',
        };
        if (item) {
            if (item.is_heading || item.sub_menu) {            // TODO: sub_menu obsolete.  Remove when gone from CMS
                navMenuItem.linkStyle = LINKSTYLE_HEADING;
            } else {
                navMenuItem.linkStyle = item.link_style || LINKSTYLE_NORMAL;
            }
            navMenuItem.category = label;
            navMenuItem.target = targetPath;
            navMenuItem.name = name;
            navMenuItem.type = type;
            if (item.link) {
                if (item.link.title) navMenuItem.label = item.link.title;
                if (item.link.href)  navMenuItem.link = item.link.href;
            }
            if (item.font_color) navMenuItem.color = item.font_color;
            if (item.background_color) navMenuItem.backgroundColor = item.background_color;
            if (item.additional_height) navMenuItem.additionalHeight = item.additional_height;

            if (target === TARGET_DESKTOP) {
                navMenuItem.column = item.column || 1;
            }

            navMenuItem.icon = item.icon || null;
        }

        return navMenuItem;
    };

    buildMenuCurrencyItemFromGraphqlItem = (item, level, parentPosition = -1) => {
        const navMenuItem = {
            level,
            parentPosition,
            label: '',
            link: '',
            linkStyle: 'Currency',
            icon: null,
            hasSubmenu: false,
        };

        if (item) {
            if (item.currency_symbol && item.currency_value) {
                navMenuItem.label = `${item.currency_value} ${item.currency_symbol}`;
            }
        }

        return navMenuItem;
    };

    buildCountryItemFromCountriesContent = (item, level, parentPosition = -1) => {
        const navMenuItem = {
            level,
            parentPosition,
            label: '',
            link: '',
            linkStyle: LINKSTYLE_NORMAL,
            icon: null,
            hasSubmenu: false,
        };

        if (item) {
            if (item.country) navMenuItem.country = item.country;
            if (item.country_flag_image) navMenuItem.country_flag_image = item.country_flag_image;
            if (item.continent) navMenuItem.continent = item.continent;
        }

        return navMenuItem;
    };

  // empty top-level item with subs
  buildMenuItemFromGraphqlGroup = (menuGroup, name, targetPath) => {
      let menuLabel = menuGroup.title;
      switch (menuGroup.title.toLowerCase()) {
          case 'languages':
              menuLabel = 'Language';
              break;
          case 'langues':
              menuLabel = 'Langue';
              break;
          case 'countries':
              menuLabel = 'Delivery Country';
              break;
          default:
              break;
      }

      return {
          level: 0,
          parentPosition: -1,
          label: menuLabel,
          link: '',
          icon: null,
          linkStyle: LINKSTYLE_NORMAL,
          menu_image: menuGroup?.menu_image,
          main_link: menuGroup?.main_link,
          url: targetPath,
          type: menuGroup.type,
          name_label: name,
          track_event: {
              tracking_event_category: menuGroup?.tracking_event_category || '',
              tracking_event_action: menuGroup?.tracking_event_action || '',
              tracking_event_label: menuGroup?.tracking_event_label || '',
              eventName: 'feature_interaction', // Requird for GA4 feature_click
          },
          style: menuGroup?.style,
          column: menuGroup.column,
      };
  };

  buildMenuItemFromCountriesContent = () => ({
      level: 0,
      parentPosition: -1,
      label: 'Delivery Country',
      link: '',
      icon: null,
      linkStyle: LINKSTYLE_NORMAL,
      menu_image: {
          image: null,
          link: {
              title: '',
              href: '',
          },
      },
      main_link: {
          title: '',
          href: '',
      },
  });

  // currencies top-level
  buildMenuItemFromGraphqlCurrenciesWidget = (menuGroup) => ({     // eslint-disable-line arrow-parens
      level: 0,
      parentPosition: -1,
      label: 'Currency',
      link: '',
      icon: null,
      linkStyle: LINKSTYLE_NORMAL,
      menu_image: menuGroup?.menu_image,
      main_link: menuGroup?.main_link,
  });

  // CMS/Graphql only --
  // Include this thing (menu block, menu item, etc.) for our current target (desktop/mobile)?
  // (e.g. if target is "Desktop", return false if thing is Mobile-only)
  // Returns True if thing has no viewports specified(i.e. valid for all) or thing's viewport contains the given target
    isThingValidForTarget = (thing, target) => {
        if (thing) {
            return !thing.viewport
            || !Array.isArray(thing.viewport)
            || thing.viewport?.length === 0
            || !thing.viewport?.[0]?.select
            || !Array.isArray(thing.viewport?.[0]?.select)
            || thing.viewport?.[0]?.select?.includes(target);
        }
        return null;
    };

    // translate list of CMS/Graphql group sub items into NavMenuItem objects for Level 3
    buildMenuListFromGraphqlList = (menuList, level, parentIndex, label, name, targetPath, type) => {
        const { target } = this.props;
        const newSubmenu = [];
        menuList.forEach((menuItem) => {
            if (this.isThingValidForTarget(menuItem, target)) {
                newSubmenu.push(this.buildMenuItemFromGraphqlItem(menuItem, level, parentIndex, label, name, targetPath, menuItem?.type || type));
            }
        });
        return newSubmenu;
    };

    buildCountriesListFromCountriesContent = (level, parentIndex) => {
        const { target, countriesContent: { country_group } } = this.props;
        const newSubmenu = [];
        if (Array.isArray(country_group) && country_group.length > 0) {
            country_group.forEach((countryItem) => {
                if (this.isThingValidForTarget(countryItem, target)) {
                    newSubmenu.push(this.buildCountryItemFromCountriesContent(countryItem, level, parentIndex));
                }
            });
        }
        return newSubmenu;
    }

    buildCurrencyListFromGraphqlList = (menuCurrencies, level, parentIndex) => {
        const { target } = this.props;
        const newSubmenu = [];
        if (menuCurrencies && Array.isArray(menuCurrencies) && menuCurrencies.length > 0) {
            menuCurrencies.forEach((menuItem) => {
                if (this.isThingValidForTarget(menuItem, target)) {
                    newSubmenu.push(this.buildMenuCurrencyItemFromGraphqlItem(menuItem, level, parentIndex));
                }
            });
        }
        return newSubmenu;
    }

    // translate list of CMS/Graphql group sub items into NavMenuItem objects for Level 2
    buildMenuGroupListFromGraphqlList = (menuList, level, parentIndex, menuType) => {
        const {
            target, name, targetPath, type,
        } = this.props;
        // currently nothing below level 1 supported
        const newSubmenu = [];
        menuList.forEach((menuItem) => {
            if (this.isThingValidForTarget(menuItem, target)) {
                const oKind = Object.keys(menuItem)[0]; // block's type
                switch (oKind) {
                    case 'menu_item':
                        // top-level menu item
                        newSubmenu.push(this.buildMenuItemFromGraphqlItem(menuItem[oKind], level, parentIndex));
                        break;

                    case 'menu_groups': {
                        // top-level item followed by list of its sub items
                        newSubmenu.push(this.buildMenuItemFromGraphqlGroup(menuItem[oKind], name, targetPath, type));
                        const parentSubIndex = newSubmenu?.length - 1;
                        if (menuItem[oKind]?.menu_item && Array.isArray(menuItem[oKind]?.menu_item)) {
                            newSubmenu[parentSubIndex].submenu = this.buildMenuListFromGraphqlList(
                                menuItem[oKind]?.menu_item,
                                1,
                                parentSubIndex,
                                newSubmenu.label,
                                menuType,
                            );
                        }
                        break;
                    }

                    default:
                        mbpLogger.logWarning({
                            function: 'MenuBuilder',
                            module: 'mbp-header',
                            message: `Unrecognized menuBlock "${oKind}"`,
                        });
                }
            }
        });
        return newSubmenu;
    };

  // Translates cmsgraphql blocks into navmenu object hierarchy
  // (see LeftMenu component for specifics)
  buildNavMenuFromGraphqlBlocks = (blocks) => {
      const {
          target, name, targetPath, countriesContent, brand,
      } = this.props;
      const newMenu = [];

      blocks.forEach((block) => {
      // menuBlock is an object with only one key:attribute.  Its key string identifies type of block.  Its attribute is object containing block's data.
          const oKind = Object.keys(block)[0]; // block's type
          const o = block[oKind]; // block's data
          if (o && this.isThingValidForTarget(o, target)) {
              switch (oKind) {
                  case 'widgets': {
                      // e.g. SEARCH
                      const currentItem = this.buildMenuItemFromGraphqlItem(o, 0);
                      currentItem.type = o.type;
                      currentItem.label = o.type;
                      newMenu.push(currentItem);
                      break;
                  }

                  case 'menu_links': // one or more top-level menu items
                      if (o.menu_link) {
                          o.menu_link.forEach((menuItem) => {
                              if (this.isThingValidForTarget(menuItem, target)) {
                                  newMenu.push(this.buildMenuItemFromGraphqlItem(menuItem, 0));
                              }
                          });
                      }
                      break;

                  case 'menu_groups': {
                      // top-level item followed by list of its sub items
                      if (o.title !== 'Countries') {
                          newMenu.push(this.buildMenuItemFromGraphqlGroup(o, name, targetPath, o.type));
                          const parentIndex = newMenu?.length - 1;
                          if (Array.isArray(o.menu_blocks) && o.menu_blocks?.length > 0) {
                              newMenu[parentIndex].submenu = this.buildMenuGroupListFromGraphqlList(
                                  o.menu_blocks,
                                  1,
                                  parentIndex,
                                  o.title,
                                  name,
                                  targetPath,
                                  o.type,
                              );
                          }
                          if (Array.isArray(o.menu_item) && o.menu_item?.length > 0) {
                              newMenu[parentIndex].submenu = this.buildMenuListFromGraphqlList(
                                  o.menu_item,
                                  1,
                                  parentIndex,
                                  o.title,
                                  o.type,
                              );
                          }
                      }
                      break;
                  }

                  case 'currency_widgets': {
                      newMenu.push(this.buildMenuItemFromGraphqlCurrenciesWidget(o));
                      const parentIndex = newMenu?.length - 1;

                      if (Array.isArray(o.currency_group) && o.currency_group?.length > 0) {
                          newMenu[parentIndex].submenu = this.buildCurrencyListFromGraphqlList(
                              o.currency_group,
                              1,
                              parentIndex,
                          );
                      }
                      break;
                  }

                  default:
                      mbpLogger.logWarning({
                          function: 'MenuBuilder',
                          module: 'mbp-header',
                          message: `Unrecognized menuBlock "${oKind}"`,
                      });
              }
          }
      });

      if (countriesContent && brand?.code === '08F') {
          newMenu.push(this.buildMenuItemFromCountriesContent());
          const parentIndex = newMenu?.length - 1;
          newMenu[parentIndex].submenu = this.buildCountriesListFromCountriesContent(
              1,
              parentIndex,
          );
      }

      return newMenu;
  };

  renderPlantsLogo=(logo) => {
      const { classes } = this.props;
      if (logo) {
          const icon = logo.image?.url;
          const title = logo.link?.title || 'logo_mobile';

          if (icon) {
              return (
                  <img id="logo-mobilePlants" className={classes.LogoMobilePlants} src={icon} alt={title} />
              );
          }
      }
      return null;
  }

  render() {
      const {
          isBot, menu, handleClose, brand, attributes, featureFlags, target, presentation_family, logo, languageChange, targetPath, name, type, countriesContent, classes,
      } = this.props;

      const isPlants = brand.code === 'PLA';
      if (
          MenuBuilder.currentLanguage !== getLanguageName()
          || !MenuBuilder.cachedMenu
      || target === TARGET_DESKTOP  // TODO: cache by menu id for desktops (desktop may have several menus)
      || MenuBuilder.cachedMenuTarget !== target
      || MenuBuilder.cachedBrand !== brand
      ) {
          MenuBuilder.menuIndex = 0;
          MenuBuilder.currentLanguage = getLanguageName();
          MenuBuilder.cachedMenu = this.buildNavMenuFromGraphqlBlocks(menu, 0, 0);
          MenuBuilder.cachedMenuTarget = target;
          MenuBuilder.cachedBrand = brand;
      }

      if (target === TARGET_DESKTOP) {
          return (<DesktopNavMenu navMenuList={MenuBuilder.cachedMenu} presentation_family={presentation_family} brand={brand} attributes={attributes} />);
      }

      const menuGroupLanguage = menu.find((item) => ['Languages', 'Langues', 'Idioma'].includes(item.menu_groups?.title))?.menu_groups;
      const menuGroupCurrencies = menu.find((item) => item.currency_widgets)?.currency_widgets;
      if (featureFlags && featureFlags['mobile-submenu-presentation'] === 'slide-replace') {
          return (
              <SlideNavMenu
                  target={targetPath}
                  type={type}
                  name={name}
                  isBot={isBot}
                  navMenuList={MenuBuilder.cachedMenu}
                  handleClose={handleClose}
                  brand={brand}
                  logo={logo}
                  languagesList={menuGroupLanguage?.menu_item}
                  currenciesList={menuGroupCurrencies?.currency_group}
                  languageChange={languageChange}
                  countriesContent={countriesContent}
              />
          );
      }
      return (
          <>
              {isPlants && <div className={classes.plantsSlideNavHeader}><div>{this.renderPlantsLogo(logo)}</div><div role="presentation" onKeyDown={() => handleClose()} onClick={() => handleClose()}><CloseIcon className={classes.plantsNavIcons} /></div></div>}
              <NavMenu
                  isBot={isBot}
                  navMenuList={MenuBuilder.cachedMenu}
                  handleClose={handleClose}
                  brand={brand}
              />
          </>
      );
  }
}

MenuBuilder.propTypes = {
    menu: arrayOf(shape({
        menu_links: shape({
            menu_link: arrayOf(shape({
                icon: string.isRequired,
                link: shape({
                    title: string.isRequired,
                    href: string.isRequired,
                }),
            })).isRequired,
        }),
        currency_widgets: shape({
            currency_group: arrayOf(shape({
                currency_name: string.isRequired,
                currency_value: string.isRequired,
                currency_symbol: string.isRequired,
            })).isRequired,
        }),
        menu_groups: shape({
            title: string.isRequired,
            menu_item: arrayOf(shape({
                icon: shape({
                    content_type: string,
                    file_size: string,
                    filename: string,
                    is_dir: bool,
                    title: string.isRequired,
                    uid: string,
                    url: string.isRequired,
                }),
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }).isRequired,
            })),
        }),
    })).isRequired,
    handleClose: PropTypes.func,
    brand: PropTypes.shape({
        domain: string.isRequired,
        code: string.isRequired,
    }),
    attributes: PropTypes.object,
    featureFlags: shape({
        'mobile-submenu-presentation': string,
    }).isRequired,
    target: PropTypes.string,
    isBot: PropTypes.bool,
    presentation_family: string.isRequired,
    logo: shape({
        image: shape({
            content_type: string.isRequired,
            description: string.isRequired,
            file_size: string.isRequired,
            filename: string.isRequired,
            title: string.isRequired,
            url: string.isRequired,
        }),
        link: shape({
            title: string,
            href: string,
        }),
    }),
    languageChange: PropTypes.func.isRequired,
    name: string,
    targetPath: string.isRequired,
    type: string.isRequired,
    countriesContent: shape({
        placeholder: string.isRequired,
        country_group: arrayOf(shape({
            country: shape({
                title: string,
                href: string,
            }),
            country_flag_image: shape({
                url: string,
            }),
        })).isRequired,
        text_color: string.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
};

MenuBuilder.defaultProps = {
    brand: {},
    attributes: {},
    target: TARGET_MOBILE,
    handleClose: null, // func?
    isBot: false,
    logo: null,
    name: '',
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    presentation_family: getPresentationFamily(state),
});

export default withStyles(styles)(
    connect(
        mapStateToProps,
        null,
    )(MenuBuilder),
);
