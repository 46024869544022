/*
    * Confidential and Proprietary.
    * Do not distribute without 1-800-Flowers.com, Inc. consent.
    * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
    */
import React, { PureComponent } from 'react';
import { object, func, bool } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import { operations } from '../../../../../state/ducks/App';
// import StarRatingComponent from 'react-star-rating-component';
const styles = (theme) => ({
    searchForm: {
        display: 'flex',
        width: '100%',
        margin: '3px 0 0',
    },
    displayNone: {
        display: 'none',
    },
    searchBoxLabel: {
        marginRight: '10px',
        position: 'relative',
    },
    searchBoxLabelHeaderRaised: {
        marginRight: '0',
        position: 'relative',
    },
    searchIcon: {
        position: 'absolute',
        top: '3px',
        left: '3px',
        color: theme.palette.grey4,
    },
    searchIconRaised: {
        position: 'relative',
        color: theme.palette.white,
    },
    searchInput: {
        border: `1px solid ${theme.palette.grey8}`,
        color: theme.palette.grey4,
        fontFamily: 'arial,sans-serif',
        fontSize: '14px',
        textIndent: '10px',
        height: '37px',
        width: '100%',
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: theme.palette.black,
        },
    },
    wrappHomePage: {
        padding: '20px 10px 10px',
    },
    wrappHeaderRaised: {
        padding: '0 10px 10px',
    },
    searchInputMenu: {
        borderRadius: '4px',
        textIndent: '4px',
        fontSize: '13px',
    },
    searchInputHome: {
        borderRadius: '0',
        textIndent: '34px',
        height: '30px',
        border: '0',
        borderBottom: `1px solid ${theme.palette.grey8}`,
        fontFamily: theme.typography.fontFamily,
    },
    searchInputHeaderRaised: {
        borderRadius: '4px 0 0 4px',
        textIndent: '4px',
        fontSize: '14px',
        height: '40px',
        color: theme.palette.black,
        fontFamily: theme.typography.fontFamily,
    },
    searchButton: {
        color: theme.palette.white,
        textTransform: 'uppercase',
        fontSize: '13px',
        backgroundColor: theme.palette.primaryButton,
        '-webkit-font-smoothing': 'antialiased',
        '&:hover': {
            backgroundColor: theme.palette.primaryButton,
        },
    },
    searchButtonMenu: {
        minWidth: '80px',
        padding: '5px 14px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    searchButtonHome: {
        minWidth: '100px',
        padding: '2px  14px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        minHeight: '27px',
    },
    searchButtonHeaderRaised: {
        minWidth: '40px',
        width: '40px',
        padding: '0',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '0 2px 2px 0',
        backgroundColor: theme.palette.grey1,
    },
    hasValue: {
        fontSize: '16px',
    },
    progress: {
        margin: '4px',
        color: theme.palette.brown5,
    },
    modalContent: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.white,
        boxShadow: theme.shadows[5],
        padding: '10px',
        textAlign: 'center',
        borderRadius: '4px',
        '&:focus': {
            outline: `solid 1px ${theme.palette.cta1}`,
            boxShadow: `0 0 10px ${theme.palette.cta1}`,
        },
    },
    descModal: {
        color: theme.palette.cta1,
        marginTop: '0px',
    },
    closeIconButton: {
        position: 'absolute',
        right: '-10px',
        top: '-10px',
        padding: '2px',
    },
    closeIcon: {
        backgroundColor: theme.palette.cta1,
        borderRadius: '100%',
        color: theme.palette.white,
        fontSize: '24px',
        padding: '1px',
    },
    resetSearchIcon: {
        padding: '4px',
        minWidth: '15px',
        maxWidth: '15px',
        height: '15px',
        position: 'absolute',
        right: '10px',
        color: theme.palette.white,
        fontSize: '12px',
        background: theme.palette.grey3,
        borderRadius: '50%',
        top: '10px',
    },
    resetSearchIconHide: {
        display: 'none',
    },
    resetSearchIconShow: {
        display: 'block',
    },
    relativePosition: {
        position: 'relative',
    },

    topSearchHeader: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '0px',
        '& li': {
            padding: '5px 0px',
            '& a': {
                textDecoration: 'none',
                color: '#3c3c35',
                fontSize: '15px',
                padding: '5',
            },
        },
    },

    serachResultContainer: {
        display: 'none',
        position: 'absolute',
        zIndex: '25',
        background: '#fff',
        top: '45px',
        width: '100%',
        border: 'solid 1px #d8d8d8',
        borderTop: 'none',
        borderBottomRightRadius: '2px',
        borderBottomLeftRadius: '2px',
        boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
        maxHeight: 'calc(100vh - 102px)',
        overflowY: 'auto',
    },
    productSuggestions: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '3px 0',
        '& li': {
            display: 'inline-block',
            minHeight: '80px',
            maxHeight: '80px',
            width: '100%',
            marginBottom: '0px',
            '& a': {
                padding: '4px 0',
                marginBottom: '10px',
                color: '#3c3c35',
                // -webkit-tap-highlight-color: rgba(48,69,98,.5);
                textDecoration: 'none',
                wordWrap: 'break-word',
                display: 'block',
                fontSize: '14px',
                '& img': {
                    float: 'left',
                    width: 'calc(52px + 12px)',
                    paddingRight: '12px',
                    display: 'block',
                },
                '& span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'block',
                },
            },
        },
    },
    starReviews: {
        display: 'inline-block !important',
        marginLeft: '10px',
    },
    thumbName: {
        padding: '5px 0',
    },
    thumbPrice: {
        padding: '5px 0 10px',
        fontWeight: 600,
    },
    borderRight: {
        borderRight: `${'1px solid'} ${theme.palette.cms?.bloomreachBorderColor || '#d8d8d8'}`,
        [theme.breakpoints.down('xs')]: {
            borderRight: 0,
        },
    },
});

class HeaderSearchResult extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate() {
        const { showClearIcon } = this.props;
        /*
            `   When a user clears search, showIcon will be set to false
                When the search is cleared we need to clear the suggest dropdown
            */
        if (showClearIcon === false) {
            this.clearData();
        }
    }

    showValueMax = (name, link, key) => {
        if (key < 5) {
            return (
                <li key={name}>
                    <Link to={`/searchterm/${link}`} onClick={() => this.clearData()}>
                        {ReactHtmlParser(name)}
                    </Link>
                </li>
            );
        }
        return null;
    };

    suggestionFunc = (sugg, value) => {
        let suggArry = '';
        if (typeof sugg !== 'undefined' && sugg.length > 0) {
            const searcVal = value.toLowerCase();
            suggArry = sugg.map((row) => {
                const data = {};
                data.name = row.dq.toLowerCase().replace(searcVal, `<b>${searcVal}</b>`);
                data.link = row.dq.replace(/\s/g, '+');
                return data;
            });
        }
        if (typeof suggArry !== 'undefined' && suggArry.length > 0) {
            return suggArry.map((data, key) => this.showValueMax(data.name, data.link, key));
        }
        return [];
    };

    clearData = () => {
        const { clearSearchResult } = this.props;
        clearSearchResult();
    };

    render() {
        const { classes, suggest } = this.props;
        return (
            <div className={classes.serachResultContainer}>
                <Grid container>
                    <Grid item sm={4} xs={12} className={classes.borderRight}>
                        <ul className={classes.topSearchHeader}>
                            {this.suggestionFunc(suggest.suggestions, suggest.q)}
                        </ul>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ul className={classes.productSuggestions}>
                            {suggest.products.map((data, key) => (key < 4 ? (
                                <li key={data.pid}>
                                    <Link
                                        to={new URL(data.url).pathname}
                                        onClick={() => this.clearData()}
                                        title={ReactHtmlParser(data.title)}
                                    >
                                        <img data-testid={`${'image-'}${data.pid}`} alt={ReactHtmlParser(data.title)} className="thumb-image" src={data.thumb_image} />
                                        <span data-testid={`${'title-'}${data.pid}`} className={classes.thumbName}>{ReactHtmlParser(data.title)}</span>
                                        <span data-testid={`${'price-'}${data.pid}`} className={classes.thumbPrice}>{`${'$'}${data.sale_price}`}</span>
                                        {/* <div className={classes.ratingStar}>
                                            <StarRatingComponent
                                                name="rating"
                                                editing={false}
                                                starCount={5}
                                                value={3.5}
                                                starColor="#a94"
                                                emptyStarColor="#a94"
                                                renderStarIcon={(index, value) => (
                                                    <span>
                                                        <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                                                    </span>

                                                )}
                                                renderStarIconHalf={() => (
                                                    <span>
                                                        <span style={{ position: 'absolute' }}><i className="far fa-star" /></span>
                                                        <span><i className="fas fa-star-half" /></span>
                                                    </span>
                                                )}
                                            />
                                            <span className={classes.starReviews}>(55 Reviews)</span>
                                        </div> */}
                                    </Link>
                                </li>
                            ) : null))}
                        </ul>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

HeaderSearchResult.propTypes = {
    classes: object.isRequired,
    suggest: object.isRequired,
    clearSearchResult: func.isRequired,
    showClearIcon: bool.isRequired,
};

const mapDispatchToProps = {
    clearSearchResult: operations.clearSearchResult,
};

export default withRouter(
    withStyles(styles, { withTheme: true })(
        connect(
            null,
            mapDispatchToProps,
        )(HeaderSearchResult),
    ),
);
