/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, arrayOf, shape, string,
} from 'prop-types';
import Copy from '../../../ContentTypeComponents/Copy';
import GraphqlSlider from '../../GraphqlCommonComponents/GraphqlSlider/GraphqlSlider';

const styles = (theme) => ({
    banner: {
        display: 'block',
        margin: 0,
        '& p': {
            margin: 0,
        },
    },
    siteWideBanner: {
        borderBottom: `1px dotted ${theme.palette.cms?.siteWideBannerBorder || '#575757'}`,
        padding: '10px 0',
        marginBottom: '20px',
    },
    left: {
        width: '85%',
    },
    right: {
        width: '15%',
        display: 'flex',
        maxHeight: 38,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    flexContainer: {
        position: 'relative',
        '& span + div': {
            display: 'inline-block',
            cursor: 'pointer',
        },
        '& span + div:focus': {
            outline: 'none',
        },
        '& button': {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        '& a': {
            position: 'absolute',
            top: '0',
            right: '0',
        },
    },
    detailLink: {
        fontSize: '0.8em',
        marginLeft: 8,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    codeDescription: {
        '& p': {
            display: 'inline',
        },
    },
    shopNow: {
        color: `${theme.palette.cms?.ShopNowColor || '#2f2f2f'}`,
        border: `${'2px solid'} ${theme.palette.cms?.ShopNowColor || '#2f2f2f'}`,
        padding: '5px 15px',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        borderRadius: '4px',
        textDecoration: 'none',
    },
    sliderStyle: {
        display: 'block !important',
        margin: '0 auto',
        maxWidth: 1380,
        padding: '0px 10px 0px 10px',
    },
});

class SiteWideBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blackListedUrls: ['/'],
            blackListedUrlPatterns: ['/checkout'],
        };
    }

    getBanner = (bannerData) => {
        const { classes } = this.props;
        const codeData = bannerData?.banner_ref?.[0] || {};
        const panel = codeData?.reference?.[0] || {};

        const unit = codeData.dimensions?.unit.toLocaleLowerCase() === 'percent' ? '%' : 'px';
        const bannerStyle = {
            width: `${codeData.dimensions?.width}${unit}`,
        };
        return (
            <div className={classes.flexContainer} style={bannerStyle || { maxWidth: 1380 }}>
                <Copy detailLinkLabel="Details" link={{ ...panel.linking, style: classes.shopNow }} data={panel.message_collection} />
            </div>
        );
    }

    getSlides() {
        const { url } = this.props;
        let currentUrl = '';
        if (typeof window !== 'undefined') {
            currentUrl = url || window.location.pathname || '/';
        }
        const slides = [];
        const { banners } = this.props;
        const { blackListedUrls } = this.state;
        banners.forEach((banner) => {
            const bannerData = banner?.site_wide_banner?.site_wide_banner_ref?.[0] || {};
            if (bannerData?.exception?.includes(currentUrl) || blackListedUrls.includes(currentUrl)) {
                return;
            }
            slides.push(this.getBanner(bannerData));
        });
        return slides;
    }

    render() {
        const { classes, url } = this.props;
        const { blackListedUrls, blackListedUrlPatterns } = this.state;
        let currentUrl = '';
        if (typeof window !== 'undefined') {
            currentUrl = url || window.location.pathname || '/';
        }
        if (blackListedUrls.includes(currentUrl)) {
            return null;
        }
        const hasBlackListedUrlPatterns = blackListedUrlPatterns.filter((curl) => currentUrl.indexOf(curl) >= 0);

        if (hasBlackListedUrlPatterns.length > 0) {
            return null;
        }

        return (
            <div className={classes.siteWideBanner}>
                <GraphqlSlider
                    items={this.getSlides()}
                    itemsPerSlide={1}
                    alignCenter
                    rootStyle={classes.sliderStyle}
                    dotNavigation
                    nextLink={(<></>)}
                    prevLink={(<></>)}
                />
            </div>
        );
    }
}

SiteWideBanner.defaultProps = {
    banners: [],
    url: null,
};

SiteWideBanner.propTypes = {
    classes: object.isRequired,
    url: string,
    banners: arrayOf(shape(
        {
            site_wide_banner: shape({
                site_wide_banner_ref: arrayOf(shape({
                    banner_ref: arrayOf(shape({
                        reference: arrayOf(shape({
                            message_collection: arrayOf(shape({
                                cta: shape({
                                    cta: string,
                                }),
                                copy: shape({
                                    copy: string,
                                }),
                                details_link: shape({
                                    presentation: string,
                                    details_link: string,
                                }),
                            })),
                            linking: shape({
                                link: shape({
                                    title: string,
                                    href: string,
                                }),
                            }),
                        })),
                    })),
                })),
            }),
        },
    )),

};

export default (withStyles(styles)(SiteWideBanner));
