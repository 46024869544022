/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import {
    object, string, shape,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { determineCollectionPrefixPath } from '../../../helpers/determineInternationalizationPath';
import { getMilVetDealId, eyebrowVAHeader } from '../VeteransAdvantagePage/helpers/utilities';

const styles = (theme) => ({
    EyebrowContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '35px',
        paddingTop: '5px',
        background: theme.palette.colorPrimary,
        color: theme.palette.common?.white,
        fontSize: '16px',
        letterSpacing: '3.2px',
        [theme.breakpoints.down('600')]: {
            fontSize: '12px',
            letterSpacing: '1.2px',
            padding: '8px 0px',
        },
        '& a': {
            fontWeight: 'bold',
            color: theme.palette.common?.white,
            marginLeft: '8px',
        },
    },
    PlantsEyebrowContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '35px',
        paddingTop: '5px',
        position: 'fixed',
        zIndex: '1081',
        background: theme.palette.colorPrimary,
        color: theme.palette.common?.white,
        fontSize: '16px',
        fontWeight: 'bolder',
        letterSpacing: '2px',
        top: 0,
        [theme.breakpoints.down('1226')]: {
            top: 0,
        },
        [theme.breakpoints.down('600')]: {
            top: 0,
            padding: '5px',
            textAlign: 'center',
            fontSize: '0.8125rem',
            letterSpacing: 'normal',
        },
        '& a': {
            color: theme.palette.common?.white,
            marginLeft: '8px',
            textDecoration: 'none',
        },
    },
});

const EyebrowBanner = (props) => {
    let { eyebrowBanner } = props;
    const { classes, brand } = props;
    const vadealid = getMilVetDealId();
    if (vadealid) eyebrowBanner = eyebrowVAHeader(vadealid);

    if (eyebrowBanner?.eyebrow_text) {
        return (
            <div
                className={brand.code === 'PLA' ? classes.PlantsEyebrowContainer : classes.EyebrowContainer}
                style={{
                    color: `${eyebrowBanner?.eyebrow_color && eyebrowBanner?.eyebrow_color}`,
                    background: `${eyebrowBanner?.eyebrow_background_color && eyebrowBanner?.eyebrow_background_color}`,
                }}
            >
                <span>
                    {eyebrowBanner.eyebrow_text}
                    {eyebrowBanner.eyebrow_link_url && eyebrowBanner.eyebrow_link_title ? <Link to={determineCollectionPrefixPath(eyebrowBanner.eyebrow_link_url)}>{eyebrowBanner.eyebrow_link_title}</Link> : ''}
                </span>
            </div>
        );
    }

    return null;
};

EyebrowBanner.propTypes = {
    classes: object.isRequired,
    eyebrowBanner: shape({
        eyebrow_background_color: string.isRequired,
        eyebrow_color: string.isRequired,
        eyebrow_link_title: string.isRequired,
        eyebrow_link_url: string.isRequired,
        eyebrow_text: string.isRequired,
    }).isRequired,
    brand: shape({
        code: string,
    }),
};

EyebrowBanner.defaultProps = {
    brand: shape({
        code: string,
    }),
};

export default withStyles(styles)(EyebrowBanner);
