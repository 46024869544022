/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Cookies  from 'universal-cookie';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import mbpLogger from 'mbp-logger';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

import { ducks as checkoutDucks } from '../../../../state/ducks/Checkout';

const {
    common: { commonSelectors },
} = checkoutDucks;

const styles = (theme) => ({
    container1: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '53px',
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        borderBottom: theme.palette.desktopHeaderMenu.border,
    },
    container2: {
        width: theme.palette.desktopHeaderMenu.maxWidth,
        display: 'inline-block',
    },

    wrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        boxSizing: 'border-box',
        height: '53px',
        margin: 0,
    },

    brandTabsStyle: {},

    brandTabStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: 'inherit',
        width: '100%',
        borderBottom: 0,
        '&:not(:last-child)': {
            borderRight: '1px solid #F5F5F5',
        },
        '& div': {
            display: 'inline-block',
            textAlign: 'center',
            width: '100%',
        },
        '&:hover::after': {
            content: "''",
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            paddingBottom: '1px',
            borderBottom: '2px solid',
            borderBottomColor: 'inherit',
        },
    },

    brandTabImageStyle: {
        // height: '32px',
        // width: '90%',
        maxWidth: '86px',
        maxHeight: '32px',
        verticalAlign: 'middle',
    },
});

class BrandTabs extends PureComponent {
    skuBuilderWithDash = (sku) => {
        let lastDashIndex = 0;
        const endIndex = sku.length;
        for (let i = 0; i < sku.length; i += 1) {
            if (sku[i] === '-') {
                lastDashIndex = i + 1;
            }
        }

        return sku.slice(lastDashIndex, endIndex);
    };

    skuBuilderWithUnderscore = (sku) => {
        const firstUnderscoreIndex = sku.indexOf('_');
        return sku.slice(firstUnderscoreIndex + 1, sku.length);
    };

    skuBuilder = (sku) => (sku.indexOf('-') !== -1 ? this.skuBuilderWithDash(sku) : this.skuBuilderWithUnderscore(sku));

    handleClick = (e, brand) => {
        const { trackEvent, orderItems } = this.props;
        trackEvent({
            eventName: 'feature_interaction', eventCategory: 'Cross Brand Nav', eventAction: 'Click', eventLabel: brand?.fullBrandName,
        });

        if (typeof window !== 'undefined') {
            e.preventDefault();
            e.stopPropagation();

            if (orderItems && orderItems.length > 0) {
                const item = orderItems[0]?.orderItems[0];

                const reqObj = [
                    `locationCode=${item.locationType}`,
                    `catalogId=${brand?.catalogId}`,
                    `skuCatentryId=${item.catalogEntryVO.catentryId}`,
                    `storeId=${brand?.storeId}`,
                    `sku=${this.skuBuilder(item.catalogEntryVO.skuCode)}`,
                    `brandId=${this.skuBuilder(item.catalogEntryVO.brandId)}`,
                    'langId=-1',
                    'rUrl=/',
                    // TODO deliveryDate, shipping, etc with the crypto
                    // `crypto=`,
                    // `deliveryDate=`',
                    // `personalization=`'',
                    // `shippingAddress=`'',
                    // `billingAddress=`'',
                ];
                const currentHost = window?.location?.host;
                const domainBrandTab = brand?.domain;
                let brandHost = currentHost.split('.');

                if (brandHost?.length === 3) {
                    brandHost[1] = domainBrandTab;
                } else if (brandHost?.length === 1) {
                    // if doesnt split is localhost:port
                    brandHost[0] = `pwa.${domainBrandTab}.${currentHost}`;
                }
                brandHost = brandHost.join('.');

                window.location.href = `${window.location.protocol}//${brandHost}/ProcessExpressRequest?${reqObj.join('&')}`;
            } else {
                window.location.href = brand?.link;
            }
        }
    }

    render() {
        // menu is array of objects with properties:
        //   label - brand code (e.g. HD)
        //   link - URL that brand image will link to
        //   icon - URL of brand's image file
        //   fullBrandName - full name of brand e.g. '1800flowers'
        //   domain - e.g. '18F'
        //   storeId - number
        //   catalogId - number
        const {
            menu, currentBrand, classes,
        } = this.props;
        const omitBrandCode = currentBrand?.code ? currentBrand.code : '';

        const docReferrer = (typeof document !== 'undefined') ? document.referrer : null;

        if (docReferrer) {
            let brandCheck;
            menu.filter((brand) => {
                const brandDomain = brand.link?.split('.')?.[1];
                const referrerDomain = docReferrer.split('.')?.[1];
                if (brandDomain === referrerDomain) {
                    brandCheck = brand.link;
                }
                return brandCheck;
            });
            if (brandCheck) {
                const cookies = new Cookies();
                cookies.set('REFERRER', brandCheck, {
                    path: '/',
                    encode: String,
                });
            }
        }
        return (
            <div className={classes.container1}>
                <div className={classes.container2}>
                    <div className={classes.wrapper}>
                        {
                            menu?.map((brand) => {
                                if (!brand?.label) {
                                    mbpLogger.logError({
                                        message: `BrandTabs: bad input or invalid brand ${brand.label}`,
                                    });
                                    return null;
                                }

                                if (brand?.label === omitBrandCode) {
                                    return null;
                                }

                                const brandCfg = brand?.label;
                                const bdrBottomBColor = brandCfg.palette && brandCfg.palette.colorPrimary ? brandCfg.palette.colorPrimary : 'black';

                                return (
                                    <a
                                        href={brand?.link}
                                        onClick={(e) => this.handleClick(e, brand)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        id={brand?.label}
                                        key={brand?.label}
                                        className={classes.brandTabStyle}
                                        style={{ borderBottomColor: bdrBottomBColor }}
                                    >
                                        <div key={brand?.label}>
                                            <img
                                                src={`${brand?.icon}?height=32&width=78`}
                                                alt={`${brand?.fullBrandName} Homepage`}
                                                className={classes.brandTabImageStyle}
                                            />
                                        </div>
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

BrandTabs.propTypes = {
    menu: PropTypes.array.isRequired,
    currentBrand: PropTypes.object.isRequired,
    orderItems: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,  // added by withStyles HOC
    trackEvent: PropTypes.func.isRequired,
};

const mapDispatcherToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),

});

const mapStateToProps = (state) => ({
    orderItems: commonSelectors.getOrderItemExcludePassport(state),
});

export default connect(mapStateToProps, mapDispatcherToProps)(withStyles(styles)(BrandTabs));
