/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useRef } from 'react';
import { withRouter } from 'react-router';
import {
    object,
    string,
    shape,
    func,
} from 'prop-types';
import { UIDReset, UIDConsumer } from 'react-uid';
import { Query } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import mbpLogger from 'mbp-logger';

// desktop header ui components
import DesktopHeaderBlock from './DesktopHeaderBlock';
import DesktopPlantsHeaderBlock from './DesktopPlantsHeaderBlock';
import SiteWideBanner from './SiteWideBanner/SiteWideBanner';

// helpers, redux, gql
import { GRAPHQL_ENV } from '../../../gql';
import findHeaderAndCountries from '../../../gql/queries/findHeaderAndCountries';

import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { setLocale } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';
import { getMilVetDealId } from '../VeteransAdvantagePage/helpers/utilities';

const TARGET_DESKTOP = 'desktop';

const setEyebrow = (block, brand, history, setIsEyebrowVisble) => {
    let eyebrowVisible = false;
    const vadealid = getMilVetDealId();
    if (block.eyebrow_banner?.eyebrow_text) {
        eyebrowVisible = true;

        if (brand.code === 'PLA') {
            const pathname = history?.location?.pathname;
            const display_section = block?.eyebrow_banner?.display_section;

            eyebrowVisible = (pathname === '/' && display_section?.includes('Home'))
                            || (pathname.indexOf('/c/') >= 0 && display_section?.includes('Collection'))
                            || (pathname.indexOf('/p/') >= 0 && display_section?.includes('Product'))
                            || (pathname.indexOf('/checkout/') >= 0 && display_section?.includes('Checkout'))
                            || vadealid;
        }
        setIsEyebrowVisble(eyebrowVisible);
    }
    return eyebrowVisible;
};

// Each row of the site's desktop header is described by a ContentStack menu block.
// This component reads those in from ContentStack, then feeds them one-by-one
// to DesktopHeaderBlock which will render
const DesktopGraphqlHeader = (props) => {
    const {
        brand, layout, history, featureFlags, languageChange, setIsEyebrowVisble,
    } = props;
    const isFirstRender = useRef(true);
    // const seed = useUIDSeed();

    // The page load is related with checkout it doesnt show the normal header.
    if (featureFlags['which-checkout-variation'] && history.location.pathname?.indexOf('/checkout/') >= 0 && ['08F', 'CAN', 'PLA'].indexOf(brand['brand-id']) === -1) {
        return null;
    }
    if (featureFlags['which-checkout-variation'] === 'food' && history.location.pathname?.indexOf('/checkout/') >= 0 && history.location.pathname?.indexOf('/checkout/order-confirmation') < 0) {
        return null;
    }

    const HEADER_QUERY = findHeaderAndCountries(brand, setLocale(), TARGET_DESKTOP, layout);
    return (
        <Query query={HEADER_QUERY}>
            {({
                loading, error, data, refetch,
            }) => {
                if (loading) {
                    return null; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        query: HEADER_QUERY,
                        component: 'DesktopGraphqlHeader.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        query: HEADER_QUERY,
                        component: 'DesktopGraphqlHeader.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                if (!data?.findHeader?.content?.entries?.length || !data.findHeader.content.entries[0]?.header_blocks?.length) {
                    mbpLogger.logError({
                        message: 'No header blocks returned from API in DesktopGraphqlHeader',
                        brand,
                    });
                    return null;
                }
                if (isFirstRender?.current && data?.findHeader?.content?.entries?.[0]?.header_blocks?.[1]?.menu?.menu_ref?.[0]?.menu_blocks?.length < 2) {
                    isFirstRender.current = false;
                    refetch();
                }
                let brandTabs = [];
                const headerBlocks = data?.findHeader?.content?.entries?.[0]?.header_blocks || [];
                brandTabs = data?.findContent?.content?.entries?.[0]?.brand_tab_links;
                const siteWideBanners = headerBlocks?.filter((block) => block?.site_wide_banner);
                // Brand tabs for SCI
                if (layout === 'SCI') {
                    const sciBrandTabs = headerBlocks?.find((header) => header?.menu?.name === 'Brand Tabs');
                    brandTabs = sciBrandTabs?.menu?.menu_ref?.[0]?.menu_blocks?.[0].menu_links?.menu_link;
                }
                const showSearch = data.findHeader?.content?.entries?.[0]?.show_search || 'yes';
                const countriesList = data.findContent?.content?.entries?.[0]?.country_group || [];

                return (
                    <>
                        <UIDReset>
                            <UIDConsumer>
                                {(id, uid) => headerBlocks.map(
                                    (block) => {
                                        const eyebrowVisible = setEyebrow(block, brand, history, setIsEyebrowVisble);
                                        return (
                                            brand.code === 'PLA' ? <DesktopPlantsHeaderBlock brandTabs={brandTabs} block={block} brand={brand} key={uid(block)} featureFlags={featureFlags} showSearch={showSearch} eyebrowVisible={eyebrowVisible} /> : <DesktopHeaderBlock brandTabs={brandTabs} block={block} brand={brand} key={uid(block)} featureFlags={featureFlags} showSearch={showSearch} languageChange={languageChange} countriesList={countriesList} />
                                        );
                                    },
                                )}
                            </UIDConsumer>
                        </UIDReset>
                        {siteWideBanners?.length > 0 && <SiteWideBanner banners={siteWideBanners} />}
                    </>
                );
            }}
        </Query>
    );
};

DesktopGraphqlHeader.propTypes = {
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    history: shape({
        location: object.isRequired,
    }).isRequired,
    layout: string.isRequired,
    featureFlags: shape({
        'which-checkout-variation': string,
    }).isRequired,
    languageChange: func.isRequired,
    setIsEyebrowVisble: func,
};

DesktopGraphqlHeader.defaultProps = {
    setIsEyebrowVisble: () => {},
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const enhance = compose(
    connect(mapStateToProps),
    withRouter,
);

export default enhance(DesktopGraphqlHeader);
