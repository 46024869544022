/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { string, func } from 'prop-types';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

export const TrackOrders = ({ image, trackEvent }) => (
    <>
        <Link
            to="/account/orders"
            data-analytics-id="track-order-track-order-link"
            id="hp-my-orders"
            data-test="hp-my-orders-icon"
            name="myOrdersIcon"
            onClick={() => trackEvent({
                eventName: 'feature_interaction', eventCategory: 'Header', eventAction: 'My Orders', eventLabel: '<<pageType>>',
            })}
        >
            <img
                src={`${image}?height=25&width=44`}
                alt="My Orders"
                aria-hidden="true"
                role="presentation"
            />
            <div>My Orders</div>
        </Link>
    </>
);

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

TrackOrders.propTypes = {
    image: string.isRequired,
    trackEvent: func.isRequired,
};

export default connect(null, mapDispatchToProps)(TrackOrders);
