/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import {
    object, string, func,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import { ducks as checkoutDucks } from '../../../../state/ducks/Checkout';

const {
    common: { commonSelectors },
} = checkoutDucks;

const styles = (theme) => ({
    searchIcon: {
        width: '20px',
        height: '20px',
        color: theme.palette.common?.black,
        verticalAlign: 'middle',
    },
    favMobile: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '4',
        fontSize: '10px',
        textDecoraction: 'none',
        textTransform: 'uppercase',
        letterSpacing: '.2px',
        borderRadius: '50%',
        padding: '6px 0px 12px',
        marginLeft: '50px',
        marginTop: '8px',
        color: theme.palette.common?.black,
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'none',
        height: '42px',
        fontFamily: theme.typography?.fontFamily,
        position: 'relative',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
});

const SearchNavIcon = ({
    image, classes, trackEvent, type,
    description,
}) => {
    if (type === 'mobile') {
        return (
            <Link to="/favorites" className={classes.favMobile}>
                <img src={image} alt={description} aria-hidden="true" />
            </Link>
        );
    }

    const scrollPage = () => {
        if (typeof window !== 'undefined') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        trackEvent({
            eventName: 'feature_interaction', eventCategory: 'Global Header', eventAction: 'Search', eventLabel: '<<pageType>>',
        });
    };

    return (
        <Link
            to="/search"
            id="pla-search"
            data-test="pla-search-icon"
            name="searchIcon"
            aria-label="searchIcon"
            aria-labelledby="searchIcon"
            onClick={scrollPage}
        >
            <img className={classes.searchIcon} src={image} alt="Cart Icon" aria-hidden="true" role="presentation" />
        </Link>
    );
};

SearchNavIcon.propTypes = {
    image: string.isRequired,
    classes: object.isRequired,
    trackEvent: func.isRequired,
    type: string,
    description: string,
};

SearchNavIcon.defaultProps = {
    type: 'desktop',
    description: '',
};

const mapDispatcherToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

const mapStateToProps = (state) => ({
    orderStatus: commonSelectors.getOrderStatus(state),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatcherToProps),
)(SearchNavIcon);
