/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Render Copy content type (usually within panel)
//
// Usage:  <Copy data={ object returned from contentstack }/>
// Result: renders copy (text)
//
// Assumes this structure:
//     data.copy   copy(text) to be rendered, in markdown format
//     data.styles array of one or more modular block style elements.
//
// example input:
//   {
//     copy: "#### Our Promise to You ####",
//     styles: [
//         {alignment: {text_align: "Left"}},
//         {font_size: {font_size: 1.25}},
//         {color: {
//             copy_color: {colorTheme: "custom", color: "#2f2f2f"},
//             background_color: {colorTheme: "none"},
//         }},
//     ]
//   }

import React, { Component } from 'react';
import { array, string, shape } from 'prop-types';
import mbpLogger from 'mbp-logger';
import ReactMarkdown from 'react-markdown';
// import gql from 'graphql-tag';
// import { Query } from 'react-apollo';
import Caret from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import generateValidCss from '../../helpers/contentstack/generateValidCss';
import parseMarkdown from '../../helpers/contentstack/parseMarkdown';
import openInNewTab from '../../helpers/contentstack/openInNewTab';
// import { GRAPHQL_ENV } from '../../gql';
import PROMO_QUERY from './PromoQuery';

class Copy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    render() {
        const { open } = this.state;
        const thisComponent = 'Copy';
        const { data, detailLinkLabel, link } = this.props;
        const all = [];
        const iconStyle = {
            width: '.65em',
            height: '.65em',
            marginTop: '-.1em',
        };
        // const stack = 'promotion';
        // const contType = 'promotion_v3';

        const handleModal = (e, windowOpen, promocode) => {
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
            e.persist();
            e.stopPropagation();
            console.log(`PromoCode is: ${promocode}`);
            this.setState({ open: windowOpen });
        };

        const btnStyle = (style) => {
            const styles = style.length ? generateValidCss(style, true) : {};
            styles.display = 'flex';
            styles.fontWeight = '400';
            styles.borderRadius = '0';
            styles.padding = '0 1em';
            styles.marginTop = '10px';
            styles.marginBottom = '10px';
            return styles;
        };

        if (data?.length) {
            data.forEach((k, i) => {
                const type = Object.keys(k)[0];
                const contentRoot = data[i]?.[type];
                let content = contentRoot?.[type];
                const hasStyles = contentRoot?.styles ? `style="${generateValidCss(contentRoot.styles)}"` : '';

                switch (type) {
                    case 'copy':
                        if (content) {
                            all.push(<ReactMarkdown key={contentRoot.copy} source={parseMarkdown(content, contentRoot.styles)} escapeHtml={false} />);
                        }
                        break;
                    case 'image':
                        if (content) {
                            content = `<img src="${content.url}" ${hasStyles} alt="${content.title}" />`;
                            all.push(<ReactMarkdown source={content} escapeHtml={false} />);
                        }
                        break;
                    case 'details_link':
                        // eslint-disable-next-line no-case-declarations
                        const presentation = contentRoot.presentation === 'Single Line' ? '  ' : '<br>';
                        // eslint-disable-next-line no-case-declarations
                        const code = content;
                        content = `<div style="z-index:1">Use code <strong>${content}</strong>${presentation}<u>${detailLinkLabel}</u></div>`;
                        all.push(
                            <>
                                <div key={1} tabIndex={0} role="link" onKeyDown={(event) => { if (event.keycode === 13) console.log(event); }} onClick={(e) => { handleModal(e, true, code); }}>
                                    <ReactMarkdown source={parseMarkdown(content, contentRoot.styles)} escapeHtml={false}  />
                                </div>
                                { open ? (
                                    <Dialog open={open} onClick={(e) => { handleModal(e, false); }}>
                                        <DialogTitle id="customized-dialog-title">
                                            Details for: {code}
                                        </DialogTitle>
                                        <MuiDialogContent dividers>
                                            <Typography gutterBottom>
                                                <PROMO_QUERY promocode={code} />
                                            </Typography>
                                        </MuiDialogContent>
                                    </Dialog>
                                ) : null }
                            </>,
                        );
                        break;
                    case 'cta': // need to handle styles differently - possibly a reference?
                        // hasStyles = `${hasStyles} ${contentRoot.styles.length !== undefined ? generateValidCss(contentRoot.styles) : ''}`;
                        if (link?.link?.href) {
                            all.push(
                                <>
                                    <Link
                                        className={link.style}
                                        to={{ pathname: link.link.href }}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...openInNewTab(link.link.href)}
                                    >
                                        {content}
                                    </Link>
                                </>,
                            );
                        } else {
                            all.push(<Button style={btnStyle(contentRoot.styles)} text endIcon={<Caret style={iconStyle}>content</Caret>}>{content}</Button>);
                        }
                        break;
                    default:
                        mbpLogger.logWarning({ component: thisComponent, message: `Unhandled block type: '${type}'` });
                }
            });
        }

        return <>{ all }</>;
    }
}

Copy.propTypes = {
    data: array.isRequired,
    detailLinkLabel: string,
    link: shape({
        style: string,
        link: shape({
            href: string,
        }),
    }),
};

Copy.defaultProps = {
    detailLinkLabel: 'See Details',
    link: null,
};

export default Copy;
