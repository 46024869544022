/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import {
    object, string, func, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Cookies  from 'universal-cookie';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

import { ducks as checkoutDucks } from '../../../../state/ducks/Checkout';

const {
    common: { commonSelectors },
} = checkoutDucks;

const styles = (theme) => ({
    cartEmpty: {
        color: theme.palette.common?.black,
    },
    cartFill: {
        color: theme.palette.common?.red,
    },
    cartTextStyle: {
        display: 'block',
    },
    cartTextCount: {
        fontSize: '11px',
        color: theme.palette.white,
        backgroundColor: theme.palette.secondary[700] || '#149e0a',
        borderRadius: '50%',
        padding: '2px 5px',
        verticalAlign: 'top',
        fontWeight: '600',
        marginLeft: '-10px',
        [theme.breakpoints.down('1025')]: {
            position: 'absolute',
            bottom: '15px',
            right: '-27px',
            fontSize: '9px',
        },
    },
    linkContainer: {
        textDecoration: 'none',
        position: 'relative',
    },
    plaCartTextCount: {
        fontSize: '0.5625rem',
        color: theme.palette.common?.black,
        right: '5px',
        fontWeight: '700',
        position: 'relative',
        opacity: '1',
        [theme.breakpoints.up(768)]: {
            position: 'absolute',
            fontSize: '9px',
        },
        [theme.breakpoints.down(767)]: {
            top: '-4px !important',
            right: '10px !important',
        },
    },
    cartIcon: {
        height: '30px',
        color: theme.palette.common?.black,
    },
    plaCartIcon: {
        height: '20px',
        width: '20px',
        verticalAlign: 'middle',
    },
    cartMobile: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '4',
        fontSize: '10px',
        textDecoraction: 'none',
        textTransform: 'uppercase',
        letterSpacing: '.2px',
        borderRadius: '50%',
        padding: '6px 0px 12px',
        marginLeft: '50px',
        marginTop: '8px',
        color: theme.palette.common?.black,
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'none',
        height: '42px',
        fontFamily: theme.typography?.fontFamily,
        position: 'relative',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    link1: {
        marginLeft: 'auto',
    },
    plaCartMobile: {
        padding: '5px',
        display: 'flex',
        position: 'relative',
        '& img': {
            width: '20px',
            height: '20px',
        },
        '& span': {
            color: '#2f2c2c',
            top: '2px',
            right: '10px',
            backgroundColor: 'transparent',
            fontWeight: '700',
        },
    },
    qtyItem: {
        top: '1px',
        right: '8px',
        width: '15px',
        color: theme.palette.mobileMenuColor,
        height: '15px',
        border: `1px solid ${theme.palette.mobileMenuColor}`,
        display: 'flex',
        position: 'absolute',
        fontSize: '12px',
        boxSizing: 'content-box',
        fontWeight: '500',
        alignItems: 'center',
        borderRadius: '50%',
        pointerEvents: 'none',
        justifyContent: 'center',
        backgroundColor: theme.palette.white,
    },
    qtyItemFood: {
        top: '3px',
        right: '3px',
        width: '15px',
        color: theme.palette.mobileMenuColor,
        height: '15px',
        display: 'flex',
        position: 'absolute',
        fontSize: '15px',
        boxSizing: 'content-box',
        fontWeight: '500',
        alignItems: 'center',
        borderRadius: '50%',
        pointerEvents: 'none',
        justifyContent: 'center',
    },
});

const setItemCountCookie = (cookieValue) => {
    const cookies = new Cookies();
    cookies.set('itemCount', cookieValue, {
        path: '/',
        encode: String,
    });
};

const Cart = ({
    orderId, image, classes, orderStatus,
    trackEvent, type,
    description, cartCount, brand, isConfirmationPage,
}) => {
    const itemCount = ((orderStatus || orderStatus === null) && orderStatus !== 'M') ? cartCount : 0;
    if (itemCount !== 0) {
        setItemCountCookie(itemCount);
    }
    let cartStyle = classes.plaCartMobile;
    if (isConfirmationPage) {
        cartStyle = `${classes?.plaCartMobile} ${classes?.link1}`;
    }

    if (type === 'mobile') {
        return (
            <Link to={`/checkout/cart/${orderId}`} className={brand?.code === 'PLA' ? cartStyle : classes.cartMobile} aria-label="cart-icon" aria-labelledby="cart-icon">
                <img src={image} alt={description} aria-hidden="true" />
                <span className={brand?.code === 'PLA' ? classes.plaCartTextCount : classes.cartTextCount}>{itemCount}</span>
            </Link>
        );
    }

    return (
        <Link
            to={`/checkout/cart/${orderId}`}
            id="hp-cart"
            data-test="hp-cart-icon"
            name="cartIcon"
            aria-label="cart-icon"
            aria-labelledby="cart-icon"
            className={brand?.code === 'PLA' ? classes.linkContainer : null}
            onClick={() => trackEvent({
                eventName: 'feature_interaction', eventCategory: 'Header', eventAction: 'Cart', eventLabel: '<<pageType>>',
            })}
        >
            <img className={brand?.code === 'PLA' ? classes.plaCartIcon : classes.cartIcon} src={image} alt="Cart Icon" aria-hidden="true" role="presentation" />
            <span className={brand?.code === 'PLA' ? classes.plaCartTextCount : classes.cartTextCount}>
                {itemCount}
            </span>
            {/* In production, item count comes from a cookie */}
        </Link>
    );
};

Cart.propTypes = {
    cartCount: string,
    orderId: string,
    image: string.isRequired,
    classes: object.isRequired,
    orderStatus: string,
    trackEvent: func.isRequired,
    type: string,
    description: string,
    brand: object,
    isConfirmationPage: bool.isRequired,
};

Cart.defaultProps = {
    cartCount: '0',
    orderId: '',
    orderStatus: '',
    type: 'desktop',
    description: '',
    brand: {},
};

const mapDispatcherToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

const mapStateToProps = (state) => ({
    cartCount: commonSelectors.getTotalItemInCart(state),
    orderId: commonSelectors.getOrderId(state),
    orderStatus: commonSelectors.getOrderStatus(state),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatcherToProps),
)(Cart);
