/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SearchResultDropDown from '../../Header/components/Search/searchResultDropDown';
import { operations, selectors } from '../../../../state/ducks/App';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

const styles = (theme) => ({
    searchForm: {
        position: 'relative',
        display: 'flex',
        height: '39px',
        width: '100%',
    },
    searchFormIe: {
        display: 'block',
        width: '100%',
        height: '39px',
        position: 'relative',
    },
    displayNone: {
        display: 'none',
    },
    searchBoxLabel: {
        marginRight: '10px',
        position: 'relative',
    },
    searchBoxLabelHeaderRaised: {
        marginRight: '0',
        position: 'relative',
    },
    searchIcon: {
        position: 'absolute',
        top: '3px',
        left: '3px',
        color: theme.palette.grey4,
    },
    searchIconRaised: {
        position: 'relative',
        color: theme.palette.white,
        fontSize: '22px',
    },
    searchInput: {
        border: `1px solid ${theme.palette.grey8}`,
        color: theme.palette.grey4,
        fontFamily: 'arial,sans-serif',
        fontSize: '14px',
        textIndent: '10px',
        height: '37px',
        width: '100%',
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: theme.palette.black,
        },
    },
    wrappHomePage: {
        padding: '20px 10px 10px',
    },
    wrappDesktopHomePage: {
        width: '52%',
    },
    wrappHeaderRaised: {
        padding: '0 10px 10px',
    },
    searchInputMenu: {
        borderRadius: '4px',
        textIndent: '4px',
        fontSize: '13px',
    },
    searchInputHome: {
        borderRadius: '0',
        textIndent: '34px',
        height: '30px',
        border: '0',
        borderBottom: `1px solid ${theme.palette.grey8}`,
        fontFamily: theme.typography.fontFamily,
    },
    searchInputDesktopHome: {
        borderRadius: '3px 0 0 3px',
        width: '100%',
        height: '38px',
        border: `1px solid ${theme.palette.colorNeutral30}`,
        fontFamily: theme.typography.fontFamily,
        '&::placeholder': {
            color: theme.palette.colorNeutral70,
        },
    },
    searchInputHeaderRaised: {
        borderRadius: '4px 0 0 4px',
        textIndent: '4px',
        fontSize: '14px',
        height: '40px',
        color: theme.palette.black,
        fontFamily: theme.typography.fontFamily,
    },
    searchButton: {
        color: theme.palette.white,
        textTransform: 'uppercase',
        fontSize: '13px',
        backgroundColor: theme.palette.primaryButton,
        '-webkit-font-smoothing': 'antialiased',
        '&:hover': {
            backgroundColor: theme.palette.primaryButton,
        },
    },
    searchButtonMenu: {
        minWidth: '80px',
        padding: '5px 14px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    searchButtonHome: {
        minWidth: '100px',
        padding: '2px  14px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        minHeight: '27px',
    },
    searchButtonDesktopHome: {
        minWidth: '100px',
        margin: '0 auto',
        padding: '2px  14px',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '700',
        minHeight: '27px',
        borderRadius: '0 3px 3px 0',
        backgroundColor: theme.palette.colorPrimary,
        '&:hover': {
            backgroundColor: theme.palette.colorPrimary,
        },
    },
    searchButtonDesktopHomeIe: {
        minWidth: '10%',
        margin: '0 auto',
        padding: '9px  14px',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '700',
        minHeight: '27px',
        borderRadius: '0 3px 3px 0',
        backgroundColor: theme.palette.colorPrimary,
        '&:hover': {
            backgroundColor: theme.palette.colorPrimary,
        },
    },
    classSearchBoxDesktopLabel: {
        margin: '0',
        width: '87%',
        display: 'inline-block',

    },
    searchButtonHeaderRaised: {
        minWidth: '40px',
        minHeight: '40px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '0 2px 2px 0',
        backgroundColor: theme.palette.grey1,
    },
    hasValue: {
        fontSize: '14px',
    },
    progress: {
        margin: '4px',
        color: theme.palette.brown5,
    },
    modalContent: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.white,
        boxShadow: theme.shadows[5],
        padding: '10px',
        textAlign: 'center',
        borderRadius: '4px',
        '&:focus': {
            outline: `solid 1px ${theme.palette.cta1}`,
            boxShadow: `0 0 10px ${theme.palette.cta1}`,
        },
    },
    descModal: {
        color: theme.palette.cta1,
        marginTop: '0px',
    },
    closeIconButton: {
        position: 'absolute',
        right: '-10px',
        top: '-10px',
        padding: '2px',
    },
    closeIcon: {
        backgroundColor: theme.palette.cta1,
        borderRadius: '100%',
        color: theme.palette.white,
        fontSize: '24px',
        padding: '1px',
    },
    resetSearchIcon: {
        padding: '4px',
        minWidth: '15px',
        maxWidth: '15px',
        height: '15px',
        position: 'absolute',
        right: '10px',
        color: theme.palette.white,
        fontSize: '12px',
        background: theme.palette.grey3,
        borderRadius: '50%',
        top: '10px',
    },
    resetSearchIconHide: {
        display: 'none',
    },
    resetSearchIconShow: {
        display: 'block',
    },
    relativePosition: {
        position: 'relative',
    },
});

const ENTER_KEY = 13; // carriage-return
const PROMPT = 'Enter Keyword or Product Number';
const PROMPTRAISED = 'Search Keyword or Product Number';
const PROMPTHOME = 'What are you looking for?';
const MODALMESSAGE = 'Please enter a keyword or Product Number to search.';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

// Props:
//    languageId    id for language (-1 for English)
//    brand         object representing brand currently displaying for user.  Fields used include:
//                      storeId, catalogId, code
export class Search extends Component {
    constructor(props) {
        super(props);

        const type = props.typeSearchBox;
        let searchPlaceholder = PROMPT;
        let currentPrompt = PROMPT;
        this.searchBrands = ['PLA', 'HD', 'SY', 'WLF'];
        if (props.searchInputTerm) {
            searchPlaceholder = props.searchInputTerm.replace('+', ' ');
        } else if (type === 'homepage') {
            searchPlaceholder = PROMPTHOME;
            currentPrompt = PROMPTHOME;
        }

        if (this.searchBrands.includes(props.brand.code)) {
            searchPlaceholder = PROMPTRAISED;
            currentPrompt = PROMPTRAISED;
        }

        this.state = {
            loading: false,
            openModal: false,
            searchPlaceholderValue: searchPlaceholder,
            searchValue: '',
            currentPrompt,
            showClearIcon: false,
            ieBrowser: false,
        };

        this.searchRef = createRef();
    }

    componentDidMount() {
        const { clearSearchResult, focusOnShow } = this.props;
        if (typeof document !== 'undefined') {
            const ieBrowser = /* @cc_on!@ */false || !!document.documentMode;
            this.setState({ ieBrowser });
            clearSearchResult({});
            if (focusOnShow) {
                this.searchRef.current.focus();
            }
        }
    }

    onChange = (event) => {
        const { getSearchResult, clearSearchResult } = this.props;
        this.setState({ searchValue: this.cleanValue(event.target.value) });
        getSearchResult(event.target.value);
        if (!event.target.value) {
            clearSearchResult({});
        }
    };

    onFocus = () => {
        const { brand } = this.props;
        const { searchPlaceholderValue, currentPrompt } = this.state;

        if (searchPlaceholderValue === currentPrompt) {
            if (this.searchBrands.includes(brand.code)) {
                this.setState({ searchPlaceholderValue: PROMPTRAISED });
            } else {
                this.setState({ searchPlaceholderValue: PROMPTHOME });
            }
        }
        if (searchPlaceholderValue !== '' && searchPlaceholderValue !== currentPrompt) {
            this.setState({ showClearIcon: true });
        }
    };

    onBlur = () => {
        const { currentPrompt } = this.state;

        this.setState((prevState) => {
            if (prevState.searchPlaceholderValue.trim() === '') {
                return { searchPlaceholderValue: currentPrompt };
            }
            return {};
        });
    };

    onKeyPress = (event) => {
        if (
            (event.which && event.which === ENTER_KEY)
            || (event.keyCode && event.keyCode === ENTER_KEY)
        ) {
            this.submitSearchForm(event);
        }
    };

    onKeyUp = () => {
        const { searchValue } = this.state;

        if (searchValue.trim() !== '') {
            this.setState({ showClearIcon: true });
        } else {
            this.setState({ showClearIcon: false });
        }
    };

    cleanValue = (v, maxLength = 100) => {
        // filter out control characters to help prevent XSS ...
        const cleanVal = v.replace(/[\x00-\x1F\x7F-\x9F]/g, ''); /* eslint no-control-regex: "off" */
        // ... and don't allow value to exceed maxLength
        return cleanVal.length <= maxLength ? cleanVal : cleanVal.substr(0, maxLength);
    };

    cleanTerm = (input) => {
        // removes extra spaces an ., example input +_)(*()()_    roses . +_)()_)))() red +_)()_))
        const a = input.replace(/[`~+!@#$%^&*()_|\-=?;:'",.<>{}/`]/g, ''); // replaces all special chars with spaces
        const b = a.replace(/\s+/g, ' '); // removes extra consecutive spaces with just one space
        const c = b.replace(/(^\s+|\s+$)/g, ''); // removes spaces in the beginning and end of search term
        // output would be "roses+red" for the url
        return c;
    }

    submitSearchForm = (evt) => {
        const {
            pageLoaded, brand, searchButtonClick, trackEvent, clearSearchResult,
        } = this.props;
        const { currentPrompt, searchValue } = this.state;
        if (typeof document !== 'undefined') {
            let searchInput = null;
            evt.preventDefault();
            const { history, typeSearchBox } = this.props;
            const searchBox = document.getElementById(`SearchBox_${typeSearchBox}`);
            if (
                searchBox
                && (searchBox.value !== PROMPT
                    || searchBox.value !== PROMPTHOME
                    || searchBox.value !== PROMPTRAISED)
            ) {
                searchInput = searchBox.value !== '' ? searchBox.value : null;
            }

            trackEvent({
                eventName: 'feature_interaction', eventCategory: 'Search', eventAction: searchValue, eventLabel: '<<pageType>>',
            });

            clearSearchResult({});

            if (searchInput && searchInput.trim() && searchInput !== currentPrompt) {
                const cleanSearchInput = this.cleanTerm(searchInput);
                const searchTearmValue = cleanSearchInput
                    .trim()
                    .replace(/ /g, '+') // replaces spaces between words with +
                    .toLowerCase();
                this.setState({ loading: true }, () => {
                    searchButtonClick();
                    history.push(`/searchterm/${searchTearmValue.length === 0 ? 'notfound' : searchTearmValue}`);
                });
                searchBox.blur();
                this.clearSearch();
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }

            if (!searchInput) {
                this.handleOpenModal();
            }
        }
        if (pageLoaded !== '' && this.searchBrands.includes(brand.code)) {
            this.setState({ loading: false });
        }
    };

    handleOpenModal = () => {
        this.setState({ openModal: true });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    clearSearch = () => {
        this.setState({
            searchPlaceholderValue: PROMPTRAISED,
            searchValue: '',
            showClearIcon: false,
        });
    };

    renderButtonSearch = (brand, classSearchButton, cssSearchIcon) => {
        if (this.searchBrands.includes(brand.code)) {
            return (
                <Button
                    className={classSearchButton}
                    aria-label="Search"
                    onClick={this.submitSearchForm}
                >
                    <SearchIcon className={cssSearchIcon} style={{ maxWidth: '40px' }} />
                </Button>
            );
        }

        return (
            <Button className={classSearchButton} id="btn-search" data-test="hp-header-search-button" name="hpSearchButton" aria-label="Search" onClick={this.submitSearchForm}>
                Search
            </Button>
        );
    };

    render() {
        const {
            classes, typeSearchBox, brand, suggestData, featureFlags,
        } = this.props;
        const {
            searchValue,
            currentPrompt,
            loading,
            searchPlaceholderValue,
            openModal,
            showClearIcon,
            ieBrowser,
        } = this.state;
        const cssSearchIcon = classes.searchIconRaised;
        // Adding the class according of the typeSearchBox.
        // typeSearchBox 'menu', 'homepage', 'header' (header is for HD) or empty
        let classSearchBoxLabel = classes.searchBoxLabel;
        let classSearchInput = [classes.searchInput];
        let classSearchButton = [classes.searchButton];
        let iconSearch = null;
        let classWrapper = null;

        switch (typeSearchBox) {
            case 'menu':
                classSearchInput.push(classes.searchInputMenu);
                classSearchButton.push(classes.searchButtonMenu);
                break;
            case 'desktop':
                classSearchInput.push(classes.searchInputDesktopHome);
                classSearchButton.push(ieBrowser ? classes.searchButtonDesktopHomeIe : classes.searchButtonDesktopHome);
                classSearchBoxLabel = classes.classSearchBoxDesktopLabel;
                classWrapper = classes.wrappDesktopHomePage;
                break;
            case 'homepage':
                classSearchInput.push(classes.searchInputHome);
                classSearchButton.push(classes.searchButtonHome);
                iconSearch = <SearchIcon className={classes.searchIcon} style={{ maxWidth: '40px' }} />;
                classWrapper = classes.wrappHomePage;
                break;
            case 'header':
                classSearchBoxLabel = classes.classSearchBoxLabelHeaderRaised;
                classSearchInput.push(classes.searchInputHeaderRaised);
                classSearchButton.push(classes.searchButtonHeaderRaised);
                classWrapper = classes.wrappHeaderRaised;
                break;
            case 'category':
                break;
            default:
                classSearchInput = [classes.searchInput];
                classSearchButton = [classes.searchButton];
                break;
        }

        // Add the class hasValue.
        if (
            searchValue !== undefined
            && searchValue !== null
            && searchValue !== ''
            && searchValue !== currentPrompt
        ) {
            classSearchInput.push(classes.hasValue);
        }

        classSearchInput = classSearchInput.join(' ');
        classSearchButton = classSearchButton.join(' ');
        const actionForm = `/searchterm/${this.cleanTerm(searchValue)}`;

        if (typeof window !== 'undefined') {
            // Avoid displaying if page is checkout
            if (featureFlags['which-checkout-variation'] && window.location.href.split('/').indexOf('checkout') >= 0) {
                return null;
            }
            if (featureFlags['which-checkout-variation'] === 'food' && window.location.href.split('/').indexOf('checkout') >= 0 && window.location.href.split('/').indexOf('order-confirmation') < 0) {
                return null;
            }
        }

        return (
            <div className={classWrapper}>
                <form
                    id="CatalogSearchForm"
                    name="CatalogSearchForm"
                    action={actionForm}
                    method="get"
                    onSubmit={this.submitSearchForm}
                    className={ieBrowser ? classes.searchFormIe : classes.searchForm}
                >
                    <label
                        className={`${classSearchBoxLabel} ${classes.relativePosition}`}
                        htmlFor="SearchBox"
                        style={{ flex: 1 }}
                    >
                        {iconSearch}
                        <input
                            id={`SearchBox_${typeSearchBox}`}
                            name={`searchTerm_${typeSearchBox}`}
                            className={classSearchInput}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                            onKeyUp={this.onKeyUp}
                            autoComplete=""
                            value={searchValue}
                            type="text"
                            readOnly={loading}
                            aria-label={currentPrompt}
                            placeholder={searchPlaceholderValue}
                            ref={this.searchRef}
                            data-test="header-search-box" // do NOT change or remove data-test attribute
                        />
                        <span className={classes.displayNone}>Search Box</span>
                        <CloseIcon
                            onClick={this.clearSearch}
                            className={`${classes.resetSearchIcon} ${classes.closeIcon} ${
                                showClearIcon ? classes.resetSearchIconShow : classes.resetSearchIconHide
                            }`}
                        />
                    </label>
                    {/* BLOOMREACH SEARCH */}
                    {Object.entries(suggestData).length > 0
                        && featureFlags
                        && featureFlags['is-bloomreach-search-enabled'] === true
                        ? (
                            <SearchResultDropDown
                                suggest={suggestData}
                                clearFunc={this.clearSearch}
                                showClearIcon={showClearIcon}
                            />
                        ) : null}

                    {!loading && this.renderButtonSearch(brand, classSearchButton, cssSearchIcon)}
                    {loading && (
                        <div className="loading-btn" id="searchBtnLoading">
                            <CircularProgress className={classes.progress} size={30} thickness={6} />
                        </div>
                    )}
                    <Modal
                        aria-describedby="search-modal-description"
                        open={openModal}
                        onClose={this.handleCloseModal}
                    >
                        <div style={getModalStyle()} className={classes.modalContent}>
                            <IconButton
                                classes={{
                                    root: classes.closeIconButton,
                                }}
                                aria-label="Close"
                                onClick={this.handleCloseModal}
                                // do NOT change or remove data-test attribute
                                data-test="search-modal-close-button"
                            >
                                <CloseIcon className={classes.closeIcon} style={{ maxWidth: '24px' }} />
                            </IconButton>
                            <Typography
                                variant="body1"
                                id="search-modal-description"
                                data-test="search-modal-description"
                                className={classes.descModal}
                            >
                                {' '}
                                {/* do NOT change or remove data-test attribute */}
                                {MODALMESSAGE}
                            </Typography>
                        </div>
                    </Modal>
                </form>
            </div>
        );
    }
}

Search.propTypes = {
    typeSearchBox: PropTypes.string,
    searchInputTerm: PropTypes.string,
    brand: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    pageLoaded: PropTypes.string,
    history: PropTypes.object.isRequired,
    searchButtonClick: PropTypes.func,
    getSearchResult: PropTypes.func.isRequired,
    suggestData: PropTypes.object,
    featureFlags: PropTypes.object,
    clearSearchResult: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    focusOnShow: PropTypes.bool,
};

Search.defaultProps = {
    typeSearchBox: null,
    searchInputTerm: '',
    pageLoaded: '',
    suggestData: {},
    featureFlags: {},
    focusOnShow: false,
    searchButtonClick: () => { },
};

const mapStateToProps = (state) => {
    const { ui } = state;
    return {
        pageLoaded: ui.pageFullyLoaded,
        suggestData: selectors.getSuggestionData(state),
        featureFlags: getFeatureFlags(state),
    };
};

const mapDispatchToProps = {
    getSearchResult: operations.getSearchResult,
    clearSearchResult: operations.clearSearchResult,
    trackEvent: tagManagerActions.trackEvent,
};

export default withRouter(
    withStyles(styles, { withTheme: true })(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(Search),
    ),
);
