/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable dot-notation */

// libraries
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { UIDReset, UIDConsumer, uid } from 'react-uid';
import {
    object, string, arrayOf, shape, bool, func,
} from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import QueryString from 'qs';
import mbpLogger from 'mbp-logger';
import { bindActionCreators } from 'redux';

// ui components
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LanguageIcon from '@material-ui/icons/Language';
import BrandTabs from './BrandTabs';
import AboveTabs from './AboveTabs';
import ConnectedSearch from './Search';
import ConnectedCart from './Cart'; // importing Cart as ConnectedCart to address linter error
import TopMenu from './TopMenu/TopMenu';
import ConnectedTrackOrders from './TrackOrders';
import ConnectedSignIn from './SignIn/SignIn';
import EyebrowBanner from './EyebrowBanner';

// gql, redux, helpers
import extractMenuAttributes from '../../../helpers/contentstack/extractMenuAttributes';
import isProductListingAd from '../../../helpers/isProductListingAd';
import { getUserRole } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getAddressSource, getCurrencyData, getDataDictionary } from '../../../../state/ducks/App/App-Selectors';
import { selectors } from '../../../../state/ducks/App';
import {
    getLanguageName, setCountryName, getCountryName, setCurrencyName,
} from '../../../../state/ducks/Member/ducks/Auth/helper/helper';
import { setCurrency } from '../../../../state/ducks/App/App-Actions';
import { determineLanguagePrefixPath } from '../../../helpers/determineInternationalizationPath';

const styles = (theme) => ({
    LogoRowContainer1: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '84px',
        backgroundColor: theme.palette.white,
        borderBottom: `1px solid ${theme.palette.colorNeutral92}`,
    },
    LogoRowContainer2: {
        width: '100%',
        maxWidth: '1400px',
        minWidth: '1024px',
        display: 'inline-block',
        margin: '0 auto',
        '@media screen and (max-width: 1430px)': {
            padding: '0 15px 0 12px',
        },
    },
    LogoRowContainer3: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
        height: '100%',
    },
    LeftContainer: {
        '& a': {
            '& img': {
                height: '41px',
            },
        },
    },
    canadaLogo: {
        '& a': {
            '& img': {
                height: 'auto',
                maxWidth: '133px',
            },
        },
    },
    plaLogo: {
        '& a': {
            '& img': {
                height: 'auto',
                maxWidth: '133px',
            },
        },
    },
    LogoRowContainer4: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '4px',
        height: '100%',
        // width: '850px',
        '& a': {
            textDecoration: 'none',
            textAlign: 'center',
            fontSize: '12px',
            color: theme.palette.black,
            borderLeft: `1px solid ${theme.palette.colorNeutral92}`,
            paddingLeft: '8px',
            order: 4,
        },
        '&>div:nth-child(1)': {
            order: 2,
            padding: '0 10px',
            borderRight: `1px solid ${theme.palette.colorNeutral92}`,
            borderLeft: `1px solid ${theme.palette.colorNeutral92}`,
            '& button': {
                padding: '6px 0',
            },
        },
        '&>div:nth-child(2)': { // language
            order: 1,
        },
        '&>div:nth-child(3)': { // currency
            order: 3,
        },
    },
    CountriesContainer: {
        display: 'flex',
    },
    ContinentContainer: {
        width: '16%',
        '& span': {
            color: theme.palette.primary?.main,
            fontWeight: 'bold',
            fontSize: '16px',
            marginBottom: '5px',
        },
        '& li': {
            padding: '5px 0px 15px !important',
            '& a': {
                textDecoration: 'none',
                color: theme.palette.primary?.main,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                '& img': {
                    width: '30px',
                    height: '20px',
                    marginRight: '10px',
                },
                '& span': {
                    margin: 'initial',
                    color: theme.palette.primary?.main,
                    fontSize: '14px',
                    fontWeight: 'normal',
                },
            },
        },
    },
    CountriesDropdown: {
        display: 'flex',
        flexDirection: 'column',
        width: '75vw',
        padding: '20px',
        '& a': {
            fontSize: '14px',
            alignSelf: 'flex-end',
            textDecoration: 'none',
            color: theme.palette.primary?.main,
            fontWeight: 'bold',
        },
    },
    ButtonCurrency: {
        color: theme.palette.black,
        fontSize: '15px',
        borderRadius: 'unset',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& p': {
            fontWeight: 'bold',
            display: 'inline-block',
            margin: 0,
        },
    },
    CurrencyLabel: {
        borderRight: 'none !important',
        padding: '6px 10px !important',
        '& p': {
            color: theme.palette.black,
            fontSize: '15px',
            fontWeight: 'bold',
            display: 'inline-block',
            margin: 0,
        },
    },
    ButtonMenuDropdown: {
        color: theme.palette.black,
        textTransform: 'none',
        fontSize: '15px',
        borderRadius: 'unset',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& img': {
            width: '20px',
            minWidth: '17px',
            borderRadius: '50%',
            border: `2px solid ${theme.palette.black}`,
            marginBottom: '-5px',
        },
        '& p': {
            fontWeight: 'bold',
            display: 'inline-block',
            margin: 0,
        },
        '&:focus-visible': {
            outline: 'auto',
        },
    },
    LanguageLabel: {
        display: 'inline-flex',
        padding: '6px 8px',
    },
    MenuCurrency: {
        '& li': {
            fontSize: '15px',
            padding: '5px 15px 5px 15px',
            textAlign: 'right !important',
        },
    },
    selected: {
        backgroundColor: `${theme.palette.primary?.main} !important`,
        color: theme.palette.white,
        '& a': {
            color: `${theme.palette.white} !important`,
        },
    },
    LanguageItem: {
        fontSize: '15px',
    },
    LogoImage: {
        paddingTop: '5px',
        width: 'auto',
    },
    LinkIcon: {
        marginTop: '2px',
        display: 'block',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'center',
        fontSize: '11px',
        paddingRight: '5px',
    },
    plaBanner: {
        width: '100%',
        maxWidth: '510px',
    },
    ArrowDropDown: {
        color: theme.palette.black,
        fontSize: '28px !important',
        marginLeft: '-8px',
    },
    LanguageIcon: {
        fontSize: '20px',
        marginRight: '8px',
    },
    OrderByPhoneContainer: {
        lineHeight: '2.2',
        padding: '0 10px',
        borderRight: `1px solid ${theme.palette.colorNeutral92}`,
        fontSize: '17px',
        '& strong': {
            color: theme.palette.colorPrimary,
        },
        paddingRight: '10px',
    },
});

const LINKSTYLE_NORMAL = 'Normal';
// const LINKSTYLE_DIVIDER = 'Divider';

const isMenuMissingOrEmpty = (blockObj, menuName) => {
    if (!blockObj.menu_ref || !Array.isArray(blockObj.menu_ref) || blockObj.menu_ref.length === 0
        || !blockObj.menu_ref[0].menu_blocks || !Array.isArray(blockObj.menu_ref[0].menu_blocks) || blockObj.menu_ref[0].menu_blocks.length === 0) {
        mbpLogger.logError({
            message: `Missing or empty ${menuName} menu in DesktopHeaderBlock`,
            block: blockObj,
        });
        return true;
    }

    return false;
};

const isMenuMissingOrEmpty2 = (blockObj, menuName) => {
    if (isMenuMissingOrEmpty(blockObj, menuName)
        || !blockObj.menu_ref[0].menu_blocks[0].menu_links
        || !blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link
        || !Array.isArray(blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link)
        || blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link.length === 0) {
        mbpLogger.logError({
            message: `block not in expected format in ${menuName} caller`,
            block: blockObj,
        });
        return true;
    }

    return false;
};

const isBrandTabsMissingOrEmpty = (brandTabs, menuName) => {
    if (
        !brandTabs
        || !Array.isArray(brandTabs)
        || brandTabs.length === 0) {
        mbpLogger.logError({
            message: `block not in expected format in ${menuName} caller`,
            block: brandTabs,
        });
        return true;
    }

    return false;
};

const callAboveTabs = (blockObj) => {
    if (isMenuMissingOrEmpty2(blockObj, 'Above Tabs')) {
        return null;
    }

    const menu = blockObj.menu_ref[0];

    // This is a mini version of MenuBuilder
    const navMenu = [];
    menu.menu_blocks[0].menu_links.menu_link.forEach((item) => {
        const navItem = {
            label: item.link && item.link.title ? item.link.title : '', // eslint-disable-line key-spacing
            link: item.link && item.link.href ? item.link.href : '', // eslint-disable-line key-spacing
        };
        item.icon && (navItem.icon = item.icon);             // eslint-disable-line no-unused-expressions
        item.font_color && (navItem.color = item.font_color);       // eslint-disable-line no-unused-expressions
        item.background_color && (navItem.backgroundColor = item.background_color); // eslint-disable-line no-unused-expressions
        item.placement && (navItem.placement = item.placement); // eslint-disable-line no-unused-expressions
        navMenu.push(navItem);
    });

    return <AboveTabs menu={navMenu} attributes={extractMenuAttributes(menu)} />;
};

const callBrandTabs = (brand, pathname, brandTabs, addressSource) => {
    if (isBrandTabsMissingOrEmpty(brandTabs, 'Brand Tabs')) {
        return null;
    }
    const pageUrl = ['/tmobile-tuesdays', '/verizon-up-400203990', '/flowers-gifts-400209995'];
    // This is a mini version of MenuBuilder
    const navMenu = [];
    brandTabs.forEach((item) => {
        const linkStyle = item.link_style || LINKSTYLE_NORMAL;
        switch (linkStyle) {
            case LINKSTYLE_NORMAL:
                if (addressSource === 'SCI') {
                    navMenu.push({
                        label: item?.link?.title ? item.link.title : '',   // brand code (e.g. HD)
                        link: item?.link?.href ? item.link.href : '',
                        icon: item?.icon || '',      // brand logo
                        fullBrandName: item?.full_brand_name || '',
                        catalogId: item?.catalog_id || '',
                        storeId: item?.store_id || '',
                        domain: item?.domain_name || '',
                        linkStyle: LINKSTYLE_NORMAL,
                    });
                } else {
                    navMenu.push({
                        label: item?.url?.title ? item.url.title : '',   // brand code (e.g. HD)
                        link: item?.url?.href ? item.url.href : '',
                        icon: item?.icon || '',      // brand logo
                        fullBrandName: item?.full_brand_name || '',
                        catalogId: item?.catalog_id || '',
                        storeId: item?.store_id || '',
                        domain: item?.domain_name || '',
                        linkStyle: LINKSTYLE_NORMAL,
                    });
                }
                break;
            default:
                mbpLogger.logWarning({
                    message: 'Do not know how to handle this kind of menu item in Brand Tabs',
                    item,
                    block: brandTabs,
                });
        }
    });

    if (pageUrl.indexOf(pathname) !== -1) {
        return false;
    }
    return <BrandTabs menu={navMenu} currentBrand={brand} />;
};

// Logo (main) row methods

const findMenuBlockObj = (menuBlocks, blockName) => {
    const results = menuBlocks.filter((menuBlock) => (Object.keys(menuBlock)[0]) === blockName);
    return results.length ? results[0][blockName] : null;
};

const findRenderBrandLogo = (menuBlocks, classes, brandCode = '') => {
    const menuBlockObj = findMenuBlockObj(menuBlocks, 'logo');
    if (menuBlockObj && menuBlockObj.image && menuBlockObj.image.url) {
        let description = '';
        if (menuBlockObj.image.link?.title) {
            description = menuBlockObj.image.link?.title;
        } else if (brandCode === 'CAN') {
            description = 'canada logo';
        } else {
            description = 'logo';
        }
        return (
            <Link to={determineLanguagePrefixPath('/')} className={classes.LogoImage}>
                <img
                    src={menuBlockObj.image.url}
                    // onError={e => addDefaultSrc(e)}    // TODO?
                    alt={description}
                    className={classes.LogoImage}
                />
            </Link>
        );
    }

    return null;
};

// The "main" row of the header (aka the Logo row) is a bit of a strange case.
// We can't quite follow the scheme of "just render everything the same in the order it appears in the block"
// because the scheme is:  Logo     search-box      everything-else-together-in-one-group
// so that's the scheme the code follows.
const renderLogoRow = (blockObj, brand, classes, location, featureFlags, addressSource, userRole, showSearch, history, languageChange, countriesList, currencyName, setCurrencyData, dataDictionary) => {
    if (isMenuMissingOrEmpty(blockObj, 'Logo Search Widgets')) {
        return null;
    }
    const isPlaEnabled = featureFlags['is-pla-flow-enabled'];
    const urlParams = QueryString.parse(location.search);
    const bannerCode = urlParams?.r || urlParams?.['?r'] || null;
    /**
     * determineSeoBanner - determines banner to show based on bannercode
     * @param {string} bCode banner code
     * @returns {string} img url
     */
    const determineSeoBanner = (bCode) => {
        if (bCode?.includes('google')) {
            return '//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt5fdc02cf0e2820a5/5e9b683bb170456000419312/pla_banner_desktop.jpg';
        }
        if (bCode?.includes('bing')) {
            return '//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt9c9ba91ea5f8a85c/5f4f9176891266279cd6e244/pla_banner_desktop_bing.jpg';
        }
        return null;
    };
    return (
        <div className={classes.LogoRowContainer1}>
            <div className={classes.LogoRowContainer2}>
                <div className={classes.LogoRowContainer3}>
                    <div className={`${classes.LeftContainer} acc`}>
                        <div className={brand?.code === 'CAN' && classes?.canadaLogo}>
                            {findRenderBrandLogo(blockObj.menu_ref?.[0]?.menu_blocks, classes, brand?.code)}
                        </div>
                    </div>
                    {isPlaEnabled && isProductListingAd(location.search) && bannerCode?.includes('pla') ? (
                        [
                            (determineSeoBanner(bannerCode)
                                ? (
                                    <img
                                        className={classes.plaBanner}
                                        src={determineSeoBanner(bannerCode)}
                                        alt="Welcome Customers"
                                        aria-hidden
                                    />
                                )
                                : null
                            ),
                        ]
                    ) : [
                        (addressSource !== 'SCI' && showSearch === 'yes'
                            ? <ConnectedSearch brand={brand} typeSearchBox="desktop" />
                            : null
                        ),
                    ]}
                    <div className={classes.LogoRowContainer4}>
                        {(brand?.code === '08F' && countriesList && history.location.pathname?.indexOf('/checkout/') < 0) && <MenuDropdown classes={classes} history={history} featureFlags={featureFlags} languageChange={languageChange} countriesList={countriesList} dataDictionary={dataDictionary} />}
                        <UIDReset>
                            <UIDConsumer>
                                {(id, uidKey) => blockObj.menu_ref?.[0]?.menu_blocks.map(
                                    (block) => <LogoRowBlock block={block} brand={brand} key={uidKey(block)} classes={classes} featureFlags={featureFlags} userRole={userRole} history={history} languageChange={languageChange} setCurrencyData={setCurrencyData} currencyName={currencyName} dataDictionary={dataDictionary} />,
                                )}
                            </UIDConsumer>
                        </UIDReset>
                        {brand?.code === 'CAN'
                            && history.location.pathname?.indexOf('/checkout/') < 0 && (
                                <div>
                                    <div className={classes.OrderByPhoneContainer}>
                                        {
                                            getLanguageName() !== '/en' ? <span>{dataDictionary['Order By Phone']}: </span> : <strong>Order By Phone: </strong>
                                        }
                                        1-800-858-5039
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const LogoRowBlock = ({
    block, brand, classes, featureFlags, userRole, history, languageChange, setCurrencyData, currencyName, dataDictionary,
}) => {
    const menuBlockKey = Object.keys(block)[0];
    const menuBlockObj = block[menuBlockKey];
    switch (menuBlockKey) {
        case 'logo':
            break;  // already rendered this

        case 'widgets':
            switch (menuBlockObj.type) {
                case '[SEARCH]':
                    break;  // already rendered this

                case '[SHOPPING_CART]':
                    break;
                    // return <Cart />;

                default:
                    mbpLogger.logError({
                        message: `Unknown menu-block widget type ${menuBlockObj.type} in DesktopHeaderBlock`,
                        brand,
                        block,
                    });
                    return null;
            }
            break;

        case 'currency_widgets':
            if (history.location.pathname?.indexOf('/checkout/') < 0) {
                return <CurrencyWidgets currencyGroup={menuBlockObj?.currency_group} classes={classes} setCurrencyData={setCurrencyData} currencyName={currencyName} dataDictionary={dataDictionary} featureFlags={featureFlags} />;
            }
            return null;

        case 'menu_groups':
            // eslint-disable-next-line no-case-declarations
            const countryTranslations = ['Countries', 'Pays', 'País'];
            if (!countryTranslations.includes(menuBlockObj.title) && history.location.pathname?.indexOf('/checkout/') < 0) {
                return <MenuDropdown featureFlags={featureFlags} menu={menuBlockObj} classes={classes} history={history} languageChange={languageChange} dataDictionary={dataDictionary} />;
            }
            break;

        case 'menu_links':
            if (menuBlockObj.menu_link && Array.isArray(menuBlockObj.menu_link) && menuBlockObj.menu_link.length) {
                return (
                    <UIDReset>
                        <UIDConsumer>
                            {(id, uidKey) => menuBlockObj.menu_link.map(
                                (menuLinkObj) => <LogoRowBlockLink obj={menuLinkObj} brand={brand} key={uidKey(menuLinkObj)} classes={classes} featureFlags={featureFlags} userRole={userRole} />,
                            )}
                        </UIDConsumer>
                    </UIDReset>
                );
            }
            return null;

        default:
            mbpLogger.logError({
                message: `Unknown menu-block type ${menuBlockKey} in DesktopHeaderBlock`,
                brand,
                block: menuBlockObj,
            });
            return null;
    }

    return null;
};

const CurrencyWidgets = ({
    currencyGroup, classes, setCurrencyData, currencyName, dataDictionary, featureFlags,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // eslint-disable-next-line no-shadow
    const handleMenuItemClick = (event, currencySymbol, currencyName) => {
        setAnchorEl(null);
        if (currencyName) {
            mbpLogger.logInfo({
                message: `Desktop: Currency changed to ${currencyName}`,
            });
            setCurrencyName(currencyName); // session storage
            setCurrencyData({ currencySymbol, currencyName }); // redux
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderOption = () => {
        const currency = dataDictionary['Currency'] || 'Currency';
        if (currencyName && Array.isArray(currencyGroup) && currencyGroup.length) {
            const currencyItem = currencyGroup.find((item) => item.currency_value === currencyName);
            if (currencyItem) {
                return <div>{currency}: <p>{`${currencyItem.currency_value} ${currencyItem.currency_symbol}`}</p></div>;
            }
        }
        return currency;
    };

    const renderLabel = (currencyValue, currencySymbol) => <div className={classes.CurrencyLabel}>{dataDictionary['Currency'] || 'Currency'}: <p>{`${currencyValue} ${currencySymbol}`}</p></div>;

    if (featureFlags['is-only-cad-currency-allowed']) {
        return renderLabel('CAD', 'C$');
    }
    if (featureFlags['is-only-usd-currency-allowed']) {
        return renderLabel('USD', '$');
    }
    return (
        <>
            <div>
                <Button id="currency-button" className={classes.ButtonCurrency} aria-controls="currency-menu" aria-haspopup="true" onClick={handleOpen} endIcon={<ArrowDropDownIcon className={classes.ArrowDropDown} />}>
                    {renderOption()}
                </Button>
            </div>
            <Menu
                id="currency-menu"
                data-testid="currency-menu"
                className={classes.MenuCurrency}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {currencyGroup.map((item) => (
                    <MenuItem key={uid(item)} selected={item.currency_value === currencyName} classes={{ selected: classes.selected }} onClick={(event) => handleMenuItemClick(event, item.currency_symbol, item.currency_value)}>{`${item.currency_value} ${item.currency_symbol}`}</MenuItem>
                ))}
            </Menu>
        </>
    );
};

CurrencyWidgets.propTypes = {
    currencyGroup: arrayOf(shape({
        currency_name: string.isRequired,
        currency_value: string.isRequired,
        currency_symbol: string.isRequired,
    })).isRequired,
    classes: object.isRequired,
    setCurrencyData: func.isRequired,
    currencyName: string.isRequired,
    dataDictionary: object.isRequired,
    featureFlags: object.isRequired,
};

const MenuDropdown = ({
    menu, classes, languageChange, countriesList, dataDictionary, featureFlags,
}) => {
    // if this flag is enabled then the default language will be english
    const isLanguageEnglish = featureFlags['is-only-language-English-allowed'];
    // if this flag is enabled then the default language will be french
    const isLanguageFrench = featureFlags['is-only-language-French-allowed'];
    const [anchorEl, setAnchorEl] = useState(null);
    const currentLanguage = getLanguageName();
    const continentsTranslations = {
        asia: currentLanguage === '/en' ? 'Asia' : dataDictionary?.['Asia'] || 'Asia',
        africa: currentLanguage === '/en' ? 'Africa' : dataDictionary?.['Africa'] || 'Africa',
        europe: currentLanguage === '/en' ? 'Europe' : dataDictionary?.['Europe'] || 'Europe',
        southAmerica: currentLanguage === '/en' ? 'South America' : dataDictionary?.['South America'] || 'South America',
        northAmerica: currentLanguage === '/en' ? 'North America' : dataDictionary?.['North America'] || 'North America',
        oceania: currentLanguage === '/en' ? 'Oceania' : dataDictionary?.['Oceania'] || 'Oceania',
    };
    const continents = [continentsTranslations.asia, continentsTranslations.africa, continentsTranslations.europe, continentsTranslations.southAmerica, continentsTranslations.northAmerica, continentsTranslations.oceania];

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, language, updateLanguageFun) => {
        setAnchorEl(null);
        languageChange(language, updateLanguageFun);
    };

    const handleCountryItemClick = (event, country) => {
        setAnchorEl(null);
        setCountryName(country);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderLanguagesOrCountries = (title) => {
        switch (title) {
            case 'Languages':
            case 'Langue':
            case 'Idioma':
                if (menu?.menu_item && Array.isArray(menu?.menu_item) && menu?.menu_item?.length) {
                    return (
                        menu?.menu_item.map((item) => (
                            <MenuItem className={classes.LanguageItem} classes={{ selected: classes.selected }} key={uid(item)} selected={item.link?.href === getLanguageName()} onClick={(event) => handleMenuItemClick(event, item.link?.href)}>
                                <div>
                                    {item.link?.title}
                                </div>
                            </MenuItem>
                        ))
                    );
                }
                return null;
            case 'Countries':
                if (countriesList && Array.isArray(countriesList) && countriesList.length) {
                    return (
                        <div className={classes.CountriesDropdown}>
                            <div className={classes.CountriesContainer}>
                                {continents.map(
                                    (continent) => {
                                        const countriesByContinent = countriesList.filter((item) => item.continent === continent).slice(0, 5);
                                        if (countriesByContinent.length) {
                                            return (
                                                <div key={uid(continent)} className={classes.ContinentContainer}>
                                                    <span>{continent}</span>
                                                    {countriesByContinent.map((countryItem) => (
                                                        <MenuItem key={uid(countryItem)} onClick={(event) => handleCountryItemClick(event, countryItem.country?.href)}>
                                                            <Link to={determineLanguagePrefixPath(countryItem.country?.href)}>
                                                                <img src={countryItem.country_flag_image?.url} alt={countryItem.country?.title} />
                                                                <span>{countryItem.country?.title}</span>
                                                            </Link>
                                                        </MenuItem>
                                                    ))}
                                                </div>
                                            );
                                        }
                                        return null;
                                    },
                                )}
                            </div>
                            <Link to={determineLanguagePrefixPath('/morecountry')}>
                                {currentLanguage === '/en' ? 'See More' : dataDictionary?.['See More'] || 'See More'}
                            </Link>
                        </div>
                    );
                }
                return null;
            default:
                mbpLogger.logError({
                    message: `Unknown menu type ${title} in DesktopHeaderBlock`,
                    menu,
                });
                return null;
        }
    };

    const renderOptionLabel = (title) => {
        switch (title) {
            case 'Languages':
            case 'Langue':
            case 'Idioma':
                if (currentLanguage && menu?.menu_item && Array.isArray(menu?.menu_item) && menu?.menu_item?.length) {
                    const languageItem = menu.menu_item.find((item) => item.link?.href?.toLowerCase() === currentLanguage);
                    if (languageItem) {
                        if (currentLanguage !== '/en') {
                            return <div>{dataDictionary?.['Language'] || 'Language'}: <p>{languageItem.link?.title}</p></div>;
                        }
                        return <div>Language: <p>{languageItem.link?.title}</p></div>;
                    }
                }
                return null;
            case 'Countries':
                // eslint-disable-next-line no-case-declarations
                const countryLabel = currentLanguage === '/en' ? 'Country' : dataDictionary?.['Country'] || 'Country';
                if (getCountryName() && countriesList && Array.isArray(countriesList) && countriesList.length) {
                    const countryItem = countriesList.find((item) => item.country?.href === getCountryName());
                    if (countryItem) {
                        return <div>{`${countryLabel}:`} <img src={countryItem.country_flag_image?.url} alt={countryItem.country?.title} /></div>;
                    }
                }
                return <div>{`${countryLabel}:`}</div>;
            default:
                mbpLogger.logError({
                    message: `Unknown menu type ${title} in DesktopHeaderBlock`,
                    menu,
                });
                return null;
        }
    };

    const languagesTranslations = ['Languages', 'Langue', 'Idioma'];
    return (
        <>
            <div>
                {(isLanguageEnglish && isLanguageFrench) || (!isLanguageEnglish && !isLanguageFrench) ? (
                    <Button
                        id={`${menu?.title ? menu?.title?.toLowerCase() : 'countries'}-button`}
                        aria-labelledby={`${menu?.title ? menu?.title?.toLowerCase() : 'countries'}-button-label`}
                        title="Choose Language"
                        aria-label="langauge-button"
                        data-testid={`${menu?.title ? menu?.title?.toLowerCase() : 'countries'}-button`}
                        className={classes.ButtonMenuDropdown}
                        aria-controls={`${menu?.title ? menu?.title?.toLowerCase() : 'countries'}-button`}
                        aria-haspopup="true"
                        onClick={handleOpen}
                        endIcon={<ArrowDropDownIcon className={classes.ArrowDropDown} />}
                    >
                        {
                            (menu?.title && languagesTranslations.includes(menu?.title))
                                ? <LanguageIcon title="Choose Language" alt="Choose Language" className={classes.LanguageIcon} />
                                : null
                        }
                        {renderOptionLabel(menu?.title ? menu?.title : 'Countries')}
                    </Button>
                ) : (
                    <div
                        className={`${classes.ButtonMenuDropdown} ${classes.LanguageLabel}`}
                        data-testid={`${menu?.title ? menu?.title?.toLowerCase() : 'countries'}-button`}
                        id={`${menu?.title ? menu?.title?.toLowerCase() : 'countries'}-button`}
                    >
                        {(menu?.title && languagesTranslations.includes(menu?.title)) ? <LanguageIcon className={classes.LanguageIcon} /> : null}
                        {renderOptionLabel(menu?.title ? menu?.title : 'Countries')}
                    </div>
                )}
            </div>
            <Menu
                id="simple-menu"
                data-testid="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {renderLanguagesOrCountries(menu?.title ? menu?.title : 'Countries')}
            </Menu>
        </>
    );
};

MenuDropdown.propTypes = {
    menu: shape({
        title: string.isRequired,
        menu_blocks: arrayOf(shape({
            menu_groups: shape({
                title: string.isRequired,
                menu_item: arrayOf(shape({
                    icon: shape({
                        content_type: string,
                        file_size: string,
                        filename: string,
                        is_dir: bool,
                        title: string.isRequired,
                        uid: string,
                        url: string.isRequired,
                    }).isRequired,
                    link: shape({
                        href: string.isRequired,
                        title: string.isRequired,
                    }).isRequired,
                })),
            }),
        })).isRequired,
        menu_item: arrayOf(shape({
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }),
        })).isRequired,
    }),
    featureFlags: shape({
        'is-only-language-English-allowed': bool,
        'is-only-language-French-allowed': bool,
    }).isRequired,
    classes: object.isRequired,
    languageChange: func.isRequired,
    countriesList: arrayOf(shape({
        country: shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
        country_flag_image: shape({
            url: string.isRequired,
        }),
        continent: string.isRequired,
    })),
    dataDictionary: shape({
        Language: string.isRequired,
    }),
};

MenuDropdown.defaultProps = {
    menu: {},
    countriesList: [],
    dataDictionary: {},
};

LogoRowBlock.propTypes = {
    classes: object.isRequired,
    block: shape({
        logo: shape({
            image: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }),
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }),
        }),
        menu_links: shape({
            menu_link: arrayOf(shape({
                icon: string,
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }),
            })),
        }),
        currency_widgets: shape({
            currency_group: arrayOf(shape({
                currency_name: string.isRequired,
                currency_value: string.isRequired,
                currency_symbol: string.isRequired,
            })),
        }),
        menu_blocks: shape({
            title: string.isRequired,
            menu_blocks: arrayOf(shape({
                menu_groups: shape({
                    title: string.isRequired,
                    menu_item: arrayOf(shape({
                        icon: shape({
                            content_type: string,
                            file_size: string,
                            filename: string,
                            is_dir: bool,
                            title: string.isRequired,
                            uid: string,
                            url: string.isRequired,
                        }).isRequired,
                        link: shape({
                            href: string.isRequired,
                            title: string.isRequired,
                        }).isRequired,
                    })),
                }),
            })).isRequired,
            menu_item: arrayOf(shape({
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }),
            })).isRequired,
        }),
    }).isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    featureFlags: shape({
        'is-pla-flow-enabled': bool,
        'is-signin-enabled': bool,
        'is-only-cad-currency-allowed': bool,
        'is-only-usd-currency-allowed': bool,
    }).isRequired,
    userRole: string.isRequired,
    history: object.isRequired,
    languageChange: func.isRequired,
    setCurrencyData: func.isRequired,
    currencyName: string.isRequired,
    dataDictionary: object.isRequired,
};

const LogoRowBlockLink = ({
    obj, brand, featureFlags, userRole,
}) => {
    const title = obj && obj.link && obj.link.title ? obj.link.title : null;
    const href = obj && obj.link && obj.link.href ? obj.link.href : '/';
    const isSigninEnabled = featureFlags['is-signin-enabled'];
    const icon = (userRole === 'P' && obj.passport_user_icon) ? obj.passport_user_icon : obj.icon;

    switch (title) {
        case 'Sign In':
            return (isSigninEnabled) ? <ConnectedSignIn image={icon} /> : null;

        case 'My Orders':
            return (isSigninEnabled) ? <ConnectedTrackOrders languageId={1} brand={brand} image={obj.icon || ''} /> : null;

        case 'Shopping Cart':
            return <ConnectedCart image={obj.icon || ''} />;

        case 'Notifications':
            return (
                <Link to={href}>
                    <img src={obj.icon} alt={title} />
                </Link>
            );
        default:
            mbpLogger.logError({
                message: `Unknown menu-block-link ${title} or unexpected structure in DesktopHeaderBlock`,
                brand,
                obj,
            });
            return null;
    }
};

LogoRowBlockLink.propTypes = {
    obj: shape({
        icon: string.isRequired,
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }),
    }).isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    featureFlags: shape({
        'is-pla-flow-enabled': bool,
        'is-signin-enabled': bool,
    }).isRequired,
    userRole: string.isRequired,
};

// Each block returned from ContentStack is a row of the desktop header.
// This component is called by DesktopGraphqlHeader to render one block (one row) of the desktop header.
const DesktopHeaderBlock = ({
    block, brand, classes, location, featureFlags, addressSource, brandTabs, countryName, presentation_family, userRole, showSearch, history, languageChange, countriesList, currencyData, setCurrencyData, dataDictionary,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    const isPlaEnabled = featureFlags['is-pla-flow-enabled'];
    const { pathname } = location;
    const { currencyName } = currencyData;

    if (block?.eyebrow_banner && !location?.pathname?.includes('/checkout/order-confirmation')) {
        return <EyebrowBanner eyebrowBanner={block.eyebrow_banner} />;
    }
    if (!blockObj.name) {
        mbpLogger.logError({
            message: 'Unknown header block type (or missing menu name) in DesktopHeaderBlock',
            brand,
            block: blockObj,
        });
        return null;
    }
    const countryURL = getCountryName();

    switch (blockObj.name) {
        case 'Above Tabs':
            if (isPlaEnabled && isProductListingAd(location.search) && addressSource !== 'SCI') {
                return null;
            }
            return callAboveTabs(blockObj);

        case 'Brand Tabs':
            if (isPlaEnabled && isProductListingAd(location.search)) {
                return null;
            }
            return callBrandTabs(brand, pathname, brandTabs, addressSource);

        case 'Logo Search Widgets':
            return renderLogoRow(blockObj, brand, classes, location, featureFlags, addressSource, userRole, showSearch, history, languageChange, countriesList, currencyName, setCurrencyData, dataDictionary);

        case 'Top Nav':
            if (isMenuMissingOrEmpty(blockObj, 'TopNav')) {
                return null;
            }
            if ((countryName !== '' && countryName !== 'united states' && countryName !== 'canada') && presentation_family !== 'food') {
                return null;
            }
            return (
                <TopMenu
                    menu={blockObj.menu_ref[0].menu_blocks}
                    brand={brand}
                    attributes={blockObj.menu_ref[0]}
                    ariaLabel="Main Navigation"
                />
            );

        case 'Top Nav International':
            if (isMenuMissingOrEmpty(blockObj, 'Top Nav International')) {
                return null;
            }
            if ((countryURL && blockObj.url && blockObj.url === countryURL) && (presentation_family === 'growthbrand') && !location?.pathname?.includes('/checkout/order-confirmation')) {
                return (
                    <TopMenu
                        menu={blockObj.menu_ref[0].menu_blocks}
                        brand={brand}
                        attributes={blockObj.menu_ref[0]}
                        ariaLabel="Main Navigation"
                    />
                );
            }

            return null;

        case 'Top Nav 2':
            if (isMenuMissingOrEmpty(blockObj, 'TopNav')) {
                return null;
            }
            return (
                <TopMenu
                    menu={blockObj.menu_ref[0].menu_blocks}
                    brand={brand}
                    attributes={blockObj.menu_ref[0]}
                    ariaLabel="Secondary Navigation"
                />
            );

        default:
            mbpLogger.logError({
                message: `Unknown header block type "${blockObj.name}" in DesktopHeaderBlock`,
                brand,
                block: blockObj,
            });
            return null;
    }
};

DesktopHeaderBlock.propTypes = {
    dataDictionary: shape({
        Language: string.isRequired,
        Currency: string.isRequired,
    }),
    block: shape({
        eyebrow_banner: shape({
            eyebrow_background_color: string,
            eyebrow_color: string,
            eyebrow_link_title: string,
            eyebrow_link_url: string,
            eyebrow_text: string,
        }),
        menu: shape({
            name: string.isRequired,
            menu_ref: arrayOf(shape({
                menu_blocks: arrayOf(shape({
                    logo: shape({
                        image: shape({
                            content_type: string,
                            file_size: string,
                            filename: string,
                            is_dir: bool,
                            title: string.isRequired,
                            uid: string,
                            url: string.isRequired,
                        }),
                        link: shape({
                            href: string.isRequired,
                            title: string.isRequired,
                        }),
                    }),
                    menu_links: shape({
                        menu_link: arrayOf(shape({
                            icon: string,
                            link: shape({
                                href: string.isRequired,
                                title: string.isRequired,
                            }),
                        })),
                    }),
                    currency_widgets: shape({
                        currency_group: arrayOf(shape({
                            currency_name: string.isRequired,
                            currency_value: string.isRequired,
                            currency_symbol: string.isRequired,
                        })),
                    }),
                    menu_blocks: shape({
                        title: string.isRequired,
                        menu_blocks: arrayOf(shape({
                            menu_groups: shape({
                                title: string.isRequired,
                                menu_item: arrayOf(shape({
                                    icon: shape({
                                        content_type: string,
                                        file_size: string,
                                        filename: string,
                                        is_dir: bool,
                                        title: string.isRequired,
                                        uid: string,
                                        url: string.isRequired,
                                    }).isRequired,
                                    link: shape({
                                        href: string.isRequired,
                                        title: string.isRequired,
                                    }).isRequired,
                                })),
                            }),
                        })).isRequired,
                        menu_item: arrayOf(shape({
                            link: shape({
                                href: string.isRequired,
                                title: string.isRequired,
                            }),
                        })).isRequired,
                    }),
                })).isRequired,
            })).isRequired,
        }),
    }).isRequired,
    classes: object.isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    location: shape({
        pathname: string.isRequired,
        search: string.isRequired,
    }).isRequired,
    featureFlags: shape({
        'is-pla-flow-enabled': bool,
        'is-signin-enabled': bool,
    }).isRequired,
    addressSource: string,
    userRole: string.isRequired,
    brandTabs: arrayOf(shape({
        brand_id: string.isRequired,
        catalog_id: string.isRequired,
        domain_name: string.isRequired,
        full_brand_name: string.isRequired,
        icon: string.isRequired,
        store_id: string.isRequired,
        url: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    })).isRequired,
    countryName: string,
    presentation_family: string.isRequired,
    showSearch: string.isRequired,
    history: object.isRequired,
    languageChange: func.isRequired,
    countriesList: arrayOf(shape({
        country: shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
        country_flag_image: shape({
            url: string.isRequired,
        }),
        continent: string.isRequired,
    })).isRequired,
    setCurrencyData: func.isRequired,
    currencyData: string.isRequired,
};

DesktopHeaderBlock.defaultProps = {
    addressSource: '',
    countryName: '',
    dataDictionary: {},
};

const mapStateToProps = (state) => ({
    addressSource: getAddressSource(state),
    countryName: selectors.getAppCountry(state),
    presentation_family: getPresentationFamily(state),
    userRole: getUserRole(state),
    currencyData: getCurrencyData(state),
    dataDictionary: getDataDictionary(state),
});

const mapDispatchToProps = (dispatch) => ({
    setCurrencyData: bindActionCreators(setCurrency, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(DesktopHeaderBlock)));
