/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { UIDReset, UIDConsumer } from 'react-uid';
import PropTypes, { object } from 'prop-types';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import styleFromAttributes from '../../../helpers/contentstack/extractMenuAttributes';
import { selectors } from '../../../../state/ducks/App';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import { setCountry } from '../../../../state/ducks/App/App-Actions';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getCountryCodeByCategoryPath } from '../../../../state/ducks/Checkout/helpers/countryCode';

const styles = (theme) => ({
    aboveTabs: {
        display: 'block',
        width: '100%',
        backgroundColor: theme.palette.colorPrimary,
    },
    aboveTabsInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: theme.palette.desktopHeaderMenu.maxWidth,
        margin: '0 auto',
        padding: '0 0 0 10px',
        listStyle: 'none',
        '& li': {
            listStyle: 'none',
            position: 'relative',
            marginRight: '25px',
            '&:hover $intDropDown': {
                display: 'flex',
            },
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    mlAuto: {
        marginLeft: 'auto',
    },
    storeLocatorIcon: {
        width: '.6em',
        height: '.6em',
        marginLeft: '1px',
        marginTop: '-2px',
        fill: theme.palette.colorPrimaryContrast,
    },
    bestofBrandsIcon: {
        width: '13px',
        height: '13px',
        fill: theme.palette.colorPrimaryContrast,
    },
    downArrowIcon: {
        width: '.9em',
        height: '.9em',
    },
    customerServiceIcon: {
        width: '.55em',
        height: '.55em',
        marginLeft: '3px',
        fill: theme.palette.colorPrimaryContrast,
    },
    link: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        color: theme.palette.colorPrimaryContrast,
        fontSize: '12px',
        lineHeight: '26px',
        textDecoration: 'none',
    },
    intDropDown: {
        display: 'none',
        justifyContent: 'center',
        width: '296px',
        marginLeft: '-80px',
        zIndex: '999',
        flexWrap: 'wrap',
        backgroundColor: '#FFF',
        position: 'absolute',
        top: '26px',
        padding: '10px',
        boxShadow: '4px 4px 6px 6px rgba(0,0,0,0.1)',
    },
    countryContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#000',
        width: '50%',
        padding: '10px',
        textDecoration: 'none',
        textTransform: 'capitalize',
        fontSize: '12px',
        lineHeight: '14px',
        '& img': {
            width: '36px',
            display: 'block',
            marginRight: '10px',
        },
    },
    moreCountryBtn: {
        height: '42px',
        lineHeight: '42px',
        fontSize: '14px',
        marginTop: '5px',
        padding: '0 20px',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: theme.palette.colorPrimary,
        '& a': {
            color: '#FFF',
            fontWeight: 'bold',
            textDecoration: 'none',
            textTransform: 'uppercase',
        },
    },
    flagImage: {
        width: '15px',
        marginLeft: '7px',
    },
});

// TODO: add cms data
const countryFlag = [
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/australia.gif',
        country: 'australia',
        href: '/australia',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/ireland.gif',
        country: 'ireland',
        href: '/ireland',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/brazil.gif',
        country: 'brazil',
        href: '/brazil',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/italy.gif',
        country: 'italy',
        href: '/italy',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/canada.gif',
        country: 'canada',
        href: '/canada',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/japan.gif',
        country: 'japan',
        href: '/japan',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/france.gif',
        country: 'france',
        href: '/france',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/mexico.gif',
        country: 'mexico',
        href: '/mexico',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/germany.gif',
        country: 'germany',
        href: '/germany',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/philippines.gif',
        country: 'philippines',
        href: '/philippines',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/india.gif',
        country: 'india',
        href: '/india',
    },
    {
        src: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/england.gif',
        country: 'united kingdom',
        href: '/england',
    },
];
const returnFlag = (classes, countryName, pathName, actionSetCountry) => {
    const countryDetails = getCountryCodeByCategoryPath(pathName);
    let data = <img src="https://cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/usa.gif" className={classes.flagImage} alt="Flag" title="Usa" />;
    const countryBasedOnPath =  countryFlag.find((name) => name.href === pathName);
    const countryBasedOnName =  countryFlag.find((name) => name.country === countryName);
    if (pathName === '/' || pathName === '//international-flower-delivery') {
        data = <img src="https://cdn1.1800flowers.com/wcsstore/Flowers/images/banners/international/flags/usa.gif" className={classes.flagImage} alt="Flag" title="Usa" />;
        actionSetCountry('united states');
    } else if (typeof countryBasedOnPath?.country !== 'undefined') {
        actionSetCountry(countryBasedOnPath?.country);
        data = <img src={countryBasedOnPath?.src} className={classes.flagImage} alt="Flag" title={countryBasedOnPath?.country} />;
    } else if (typeof countryBasedOnName?.country !== 'undefined') {
        actionSetCountry(countryBasedOnName?.country);
        data = <img src={countryBasedOnName?.src} className={classes.flagImage} alt="Flag" title={countryBasedOnName?.country} />;
    } else {
        actionSetCountry(countryDetails?.countryName);
    }
    return data;
};
const GetIcon = ({
    itemIcon, styleOverrides, classes, countryName, pathName, actionSetCountry,
}) => {
    switch (itemIcon.toUpperCase()) {
        case '[PIN]':
            return <LocationOnOutlinedIcon className={classes.storeLocatorIcon} style={styleOverrides} />;
        case '[CTA]':
            return <PlayArrowIcon className={classes.bestofBrandsIcon} style={styleOverrides} />;
        case '[FLAG]':
            return (
                <>
                    {returnFlag(classes, countryName, pathName, actionSetCountry)}
                    <ArrowDropDownIcon className={classes.downArrowIcon} style={styleOverrides} />
                </>
            );
        case '[?]':
            return <HelpOutlineIcon className={classes.customerServiceIcon} style={styleOverrides} />;
        default:
            return null;
    }
};

GetIcon.propTypes = {
    itemIcon: PropTypes.string.isRequired,
    styleOverrides: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired, // added by withStyles HOC
    countryName: PropTypes.string.isRequired,
    pathName: PropTypes.string.isRequired,
    actionSetCountry: PropTypes.func.isRequired,
};

const AboveTabs = (props) => {
    const {
        menu, attributes, classes, theme, trackEvent, countryName, actionSetCountry, location, featureFlags,
    } = props;
    // TODO: constants and descriptions for style profiles.
    // style profile 0 -- (e.g. 18F & most others) -- no padding on menu items, except lots of left/right padding on "our brands" item
    // style profile 1 -- (e.g. HD, WF) -- each item has 8px padding all around; height 32px
    const styleProfile = attributes && attributes.styleProfile ? parseInt(attributes.styleProfile, 10) : 0;
    const menuStyleOverrides = {};
    if (attributes.styleProfile === 1) {
        menuStyleOverrides.height = '32px';
    }
    if (attributes.backgroundColor) {
        menuStyleOverrides.backgroundColor = attributes.backgroundColor;
    }

    const isWCSAccessibilityEnabled = featureFlags['is-wcs-accessibility-mode-enabled'];
    const handleClick = (e, label) => {
        trackEvent({
            eventName: 'feature_interaction', eventCategory: 'Header', eventAction: label, eventLabel: '<<pageType>>',
        });
        if (isWCSAccessibilityEnabled && label.toLowerCase().includes('accessibility') && typeof window !== 'undefined') {
            e.preventDefault();
            const cookies = new Cookies();
            const hostname = window.location.hostname.split('.')[1];
            const redirectUrl = `https://www.${hostname}.com${location.pathname}?UsableNetAssistive=true`;
            cookies.set('destination', 'ww42', { path: '/', domain: `.${hostname}.com` });
            window.location.href = redirectUrl;
        }
    };

    return (
        <div className={classes.aboveTabs} style={menuStyleOverrides}>
            <ul className={classes.aboveTabsInnerContainer}>
                <UIDReset>
                    <UIDConsumer>
                        {(id, uid) => menu.map(
                            (item, n) => {
                                const itemStyleOverrides = styleFromAttributes(attributes);

                                const iconStyleOverrides = {};
                                if (item.color) {
                                    itemStyleOverrides.color = item.color;
                                    iconStyleOverrides.fill = item.color;
                                }
                                if (item.backgroundColor) {
                                    itemStyleOverrides.backgroundColor = item.backgroundColor;
                                    if (n === menu.length - 1 && styleProfile === 0) {     // last menu item gets special treatment
                                        itemStyleOverrides.paddingLeft = '16px';
                                        itemStyleOverrides.paddingRight = '16px';
                                    }
                                    iconStyleOverrides.backgroundColor = item.backgroundColor;
                                } else if (n === menu.length - 1) {     // last menu item gets special treatment
                                    itemStyleOverrides.backgroundColor = theme.palette.colorSuccess;
                                    if (styleProfile === 0) {
                                        itemStyleOverrides.paddingLeft = '16px';
                                        itemStyleOverrides.paddingRight = '16px';
                                    }
                                    iconStyleOverrides.backgroundColor = theme.palette.colorSuccess;
                                }
                                if (styleProfile === 1) {
                                    itemStyleOverrides.paddingTop = '3px';
                                    itemStyleOverrides.paddingRight = '8px';
                                    itemStyleOverrides.paddingBottom = '3px';
                                    itemStyleOverrides.paddingLeft = '8px';
                                }

                                return (
                                    <li key={`aboveTabLi${uid(item)}`} className={item.placement === 'right' ? classes.mlAuto : ''}>
                                        <a
                                            className={classes.link}
                                            href={item.link}
                                            style={itemStyleOverrides}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) => handleClick(e, item.label)}
                                        >
                                            {item.label}
                                            {item.icon && <GetIcon actionSetCountry={actionSetCountry} countryName={countryName} itemIcon={item.icon} classes={classes} styleOverrides={iconStyleOverrides} pathName={location.pathname} />}
                                        </a>
                                        {
                                            item.label.toLowerCase() === 'international delivery' ? (
                                                <div className={classes.intDropDown}>
                                                    {countryFlag && countryFlag.map((country, i) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <Link id="countryContainer" className={classes.countryContainer} onClick={() => actionSetCountry(country.country)} to={country.href} key={`intlDropDown${i}`}>
                                                            <img src={country.src} alt={country.country} title={country.country} />
                                                            <span>{country.country}</span>
                                                        </Link>
                                                    ))}
                                                    <button className={classes.moreCountryBtn} type="button">
                                                        <Link to="/international-flower-delivery">
                                                            More countries
                                                        </Link>
                                                    </button>
                                                </div>
                                            ) : null
                                        }
                                    </li>
                                );
                            },
                        )}
                    </UIDConsumer>
                </UIDReset>
            </ul>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: tagManagerActions.trackEvent,
    actionSetCountry: bindActionCreators(setCountry, dispatch),
});

AboveTabs.propTypes = {
    menu: PropTypes.array.isRequired,
    attributes: PropTypes.object,
    classes: PropTypes.object.isRequired, // added by withStyles HOC
    theme: PropTypes.object.isRequired, // added by withTheme HOC
    trackEvent: PropTypes.func.isRequired,
    countryName: PropTypes.string,
    actionSetCountry: PropTypes.func.isRequired,
    location: object.isRequired,
    featureFlags: object.isRequired,
};
const mapStateToProps = (state) => ({
    countryName: selectors.getAppCountry(state),
    featureFlags: getFeatureFlags(state),
});
AboveTabs.defaultProps = {
    attributes: {},
    countryName: '',
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(AboveTabs))));
