/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import mbpLogger from 'mbp-logger';
import MenuBuilder from '../../../Header/components/LeftMenu/MenuBuilder/MenuBuilder';
import extractMenuAttributes from '../../../../helpers/contentstack/extractMenuAttributes';

const styles = (theme) => ({
    topMenuBar: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        width: '100%',
        height: theme.palette.navHeight || '52px',
        whiteSpace: 'nowrap',
        borderBottom: theme.palette.desktopHeaderMenu.border,
    },
    plantsTopMenuBar: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f2f2f2ed',
        width: '100%',
        height: '140px',
        whiteSpace: 'nowrap',
    },
    plantsTopConfirmOrderMenuBar: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f2f2f2ed',
        width: '100%',
        height: '110px',
        whiteSpace: 'nowrap',
    },
    plantsHeaderHide: {
        display: 'none !important',
    },
});

//
// The horizontal menu that appears at top of home and possibly other pages
// It takes input from graphql/CMS (PWA system)
//
// Props:
//    menu               Hierarchical array of menu items (hdr.data.findHeader.content.entries[0].header_blocks[0].menu.menu_ref[0].menu_blocks)
//    brand              brand object (must contain brand.code)
//
class TopMenu extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
    // An error occurred.  Update state to tell renderer.
        return { hasError: true };
    }

    componentDidCatch(jsError, reactInfo) {
        const { menu, brand } = this.props;
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            message: `Unhandled Error in TopMenu \n jsError = ${jsError} \n reactInfo = ${reactInfo} \n menu data = ${menu} \n brand data = ${brand}`,
        });
    }

    render() {
        const { menu, brand, attributes, classes, ariaLabel, headerShow = true, isConfirmOrder } = this.props;  // eslint-disable-line object-curly-newline
        const { hasError } = this.state;

        if (hasError) {
            return null;
        }
        return (
            <nav className={brand.code === 'PLA' ? `${isConfirmOrder ? classes.plantsTopConfirmOrderMenuBar : classes.plantsTopMenuBar} ${!headerShow ? classes.plantsHeaderHide : null}` : classes.topMenuBar} style={extractMenuAttributes(attributes)} aria-label={ariaLabel}>
                <MenuBuilder menu={menu} brand={brand} attributes={attributes} target="Desktop" />
            </nav>
        );
    }
}

TopMenu.propTypes = {
    menu: PropTypes.array.isRequired,
    brand: PropTypes.object.isRequired,
    attributes: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    headerShow: PropTypes.bool,
    isConfirmOrder: PropTypes.bool,
};
TopMenu.defaultProps = {
    headerShow: true,
    isConfirmOrder: false,
};

export default withStyles(styles)(TopMenu);
