/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import {
    object, number, bool, array, string, node,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';

const styles = (theme) => ({
    gqlSlider: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    gqlSliderContainer: {
        display: 'flex',
        position: 'relative',
        width: '100%',
    },
    leftNav: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        border: 0,
        left: '-25px',
        top: '40%',
        '&:focus': {
            outline: 'none', // TODO: Need to add outline for accessibility
        },
    },
    rightNav: {
        position: 'absolute',
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
        right: '-25px',
        top: '40%',
        '&:focus': {
            outline: 'none', // TODO: Need to add outline for accessibility
        },
    },
    dotNavigatorContainer: {
        textAlign: 'center',
        position: 'absolute',
        bottom: '0',
        left: '50%',
    },
    dotSelected: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        background: theme.palette.cms?.sliderNavDotSelectedBackground || 'rgb(47, 47, 47)',
        border: `${'1px solid'} ${theme.palette.cms?.sliderNavDotSelectedBackground || 'rgb(47, 47, 47)'}`,
        marginRight: 5,
    },
    dotUnselected: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        background: theme.palette.cms?.sliderNavDotUnSelectedBackground || '#fff',
        border: `${'1px solid'} ${theme.palette.cms?.sliderNavDotSelectedBackground || 'rgb(47, 47, 47)'}`,
        marginRight: 5,
    },
});

const getKey = (itemIndex) => `${itemIndex}-key`; // TODO: will replace with uiSeed

class GraphqlSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activatedSetIndex: 0,
            direction: 'left',
        };
    }

    getItems = () => {
        const { itemsPerSlide, items = [] } = this.props;
        const reduces = [];
        for (let i = 0, len = items.length; i < len; i += itemsPerSlide) {
            reduces.push(items.slice(i, i + itemsPerSlide));
        }
        return reduces;
    }

    getDotNavigation = () => {
        const { activatedSetIndex } = this.state;
        const { classes } = this.props;
        return (this.getItems() || []).map((navigator, index) => (
            <button
                type="button"
                aria-label="Navigation"
                tabIndex="0"
                onClick={() => {
                    if (activatedSetIndex > index) {
                        this.setState({ activatedSetIndex: index, direction: 'right' });
                    } else {
                        this.setState({ activatedSetIndex: index, direction: 'left' });
                    }
                }}
                key={getKey(index)}
                className={(activatedSetIndex === index) ? classes.dotSelected : classes.dotUnselected}
            />
        ));
    }

    navigate = (type = 'next') => {
        const { activatedSetIndex } = this.state;
        const { loop } = this.props;
        const slidesLength = this.getItems().length;
        if (type === 'next') {
            if (loop) {
                if ((slidesLength - 1) === activatedSetIndex) {
                    this.setState({ activatedSetIndex: 0 });
                } else {
                    this.setState({ activatedSetIndex: (activatedSetIndex + 1) });
                }
            } else if ((slidesLength - 1) === activatedSetIndex) {
                this.setState({ activatedSetIndex });
            } else {
                this.setState({ activatedSetIndex: (activatedSetIndex + 1) });
            }
        } else if (type === 'prev') {
            if (loop) {
                if (activatedSetIndex === 0) {
                    this.setState({ activatedSetIndex: (slidesLength - 1) });
                } else {
                    this.setState({ activatedSetIndex: (activatedSetIndex - 1) });
                }
            } else if (activatedSetIndex === 0) {
                this.setState({ activatedSetIndex });
            } else {
                this.setState({ activatedSetIndex: (activatedSetIndex - 1) });
            }
        }
    }

    render() {
        const {
            classes,
            prevLink,
            align,
            alignCenter,
            loop,
            nextLink,
            dotNavigation,
            rootStyle,
            items = [],
        } = this.props;
        const { activatedSetIndex, direction } = this.state;
        const itemsLength = this.getItems().length;
        const alignAll = alignCenter ? 'center' : 'left';
        return (
            <div className={`${classes.gqlSliderContainer}`}>
                {(loop || activatedSetIndex !== 0) && items.length > 0 && (
                    <button
                        type="button"
                        onClick={() => {
                            this.navigate('prev');
                            this.setState({ direction: 'right' });
                        }}
                        className={classes.leftNav}
                    >{prevLink}
                    </button>
                )}
                <div style={{ justifyContent: (itemsLength - 1) !== activatedSetIndex ? align : alignAll }} className={`${classes.gqlSlider} ${rootStyle}`}>
                    {(this.getItems() || []).map((item, index) => (
                        <div key={getKey(index)} style={{ display: (activatedSetIndex === index) ? 'flex' : 'none' }}>
                            {item.map((getItem, itemIndex) => (
                                <Slide key={getKey(itemIndex)} direction={direction} in={(activatedSetIndex === index)}>
                                    {getItem}
                                </Slide>
                            ))}
                        </div>
                    ))}
                    {dotNavigation && (
                        <div className={classes.dotNavigatorContainer}>{this.getDotNavigation()}</div>
                    )}
                </div>
                {(loop || activatedSetIndex !== (itemsLength - 1)) && items.length > 0 && (
                    <button
                        type="button"
                        onClick={() => {
                            this.navigate();
                            this.setState({ direction: 'left' });
                        }}
                        className={classes.rightNav}
                    >{nextLink}
                    </button>
                )}
            </div>
        );
    }
}

GraphqlSlider.propTypes = {
    classes: object.isRequired,
    items: array.isRequired,
    itemsPerSlide: number,
    loop: bool,
    align: string,
    dotNavigation: bool,
    alignCenter: bool,
    prevLink: node,
    nextLink: node,
    rootStyle: string,
};

GraphqlSlider.defaultProps = {
    itemsPerSlide: 3,
    loop: false,
    rootStyle: null,
    align: 'center',
    alignCenter: false,
    dotNavigation: false,
    prevLink: (<span>Prev</span>),
    nextLink: (<span>Next</span>),
};

export default withStyles(styles)(GraphqlSlider);
